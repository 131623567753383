import React, { Component } from "react";
import ReactGA from "react-ga4";
import {
  getPlayers,
  deletePlayer,
  bulkUpdateRosterNumbers,
  recruitFreeAgent,
} from "../../services/playerService";
import { getCurrentUser } from "../../services/userService";
import { getTeams } from "../../services/teamService";
import { paginate } from "../../utils/paginate";
import orderBy from "lodash/orderBy";
import { navigateTo } from "./../common/customs/customLinks";
import { splitQuery } from "../../utils/splitQuery";
import toast from "../../utils/toast";
import CustomConfirm from "../common/customs/customConfirm";
import QuickLinks from "../common/pageComponents/quickLinks";
import { CLink } from "../common/customs/customLinks";
import PlayersButtonLineup from "./playersButtonLineup";
import HeaderContext from "../../context/headerContext";
import allowables from "../../utils/allowables";
import NonFormSelect from "../common/form/nonFormSelect";
import TableCardSwitcher from "../common/cardView/tableCardSwitcher";
import PlayersTable from "./playersTable";
import PlayersCardView from "./playersCardView";

class Players extends Component {
  static contextType = HeaderContext;
  state = {
    players: [],
    teams: [],
    divisions: [],
    pageSize: 50,
    currentPage: 1,
    searchQuery: "",
    selectedTeam: { _id: 0, name: "All Teams" },
    sortColumn: { path: "name", order: "asc" },
    captain: false,
    selectedPlayer: null,
    deleteDialog: "",
    deleteOpen: false,
    deleteTwoOpen: false,
    deleteTwoDialog: "",
    recruitOpen: false,
    moveToFreeAgentsOpen: false,
    recruitPlayer: null,
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const response = await getPlayers({
      callback: this.indicateProgress,
      bar: 0,
    });
    const user = getCurrentUser();
    if (response.status === 200) this.setState({ players: response.data });
    const teamsResponse = await getTeams({
      callback: this.indicateProgress,
      bar: 1,
    });
    if (teamsResponse.status === 200) {
      let dbTeams = orderBy(teamsResponse.data, ["name"], ["asc"]);
      dbTeams.forEach((t) => {
        t.numberOfPlayers = response.data.filter(
          (p) => p.teamID === t._id
        ).length;
      });
      const teams = [
        {
          _id: 0,
          name: "All Teams",
          numberOfPlayers: response.data.length,
        },
        {
          _id: "freeAgents",
          name: "Free Agents",
          numberOfPlayers: response.data.filter(
            (p) => p.teamName === "Free Agents"
          ).length,
        },
      ];
      teams.push({
        _id: "suspended",
        name: "All Suspended Players",
        numberOfPlayers: response.data.filter((p) => p.suspendedFlag > 0)
          .length,
      });
      teams.push(...dbTeams);
      const captainOrAdmin =
        teams.some(
          (t) => t.teamCaptainID && t.teamCaptainID._id === user._id
        ) || user.role.includes("admin");
      this.setState({ teams, captainOrAdmin });
    }
    const queryString = splitQuery(this.props.location.search);
    let searchQuery = "";
    let currentPage = 1;
    let sortColumn = { ...this.state.sortColumn };
    let selectedTeam = this.state.selectedTeam;
    queryString.forEach((q) => {
      const each = q.split("=");
      searchQuery = each[0].includes("search") ? each[1] : searchQuery;
      currentPage = each[0].includes("page") ? Number(each[1]) : currentPage;
      selectedTeam._id =
        each[0].includes("id") && each[1] !== "null" && each[1] !== "0"
          ? each[1]
          : each[1] === "0"
          ? 0
          : selectedTeam._id;
      selectedTeam.name =
        each[0].includes("name") && each[1] !== "null"
          ? each[1].split("%20").join(" ")
          : selectedTeam.name;
      sortColumn.path = each[0].includes("path") ? each[1] : sortColumn.path;
      sortColumn.order = each[0].includes("order") ? each[1] : sortColumn.order;
    });
    if ((!selectedTeam._id && selectedTeam._id !== 0) || !selectedTeam.name)
      selectedTeam = { _id: null, name: null };
    this.setState({
      searchQuery,
      currentPage,
      selectedTeam,
      sortColumn,
    });
    this.context.setLoading(false);
  }

  downloadColumns = [
    "name",
    "teamName",
    "shirtNumber",
    "sport",
    "suspendedFlag",
    "runningYellows",
  ];

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  pushHistoryString = (search, sortColumn, selectedTeam, currentPage) => {
    this.props.history.replace(
      `?search=${search}&path=${sortColumn.path}&order=${sortColumn.order}&name=${selectedTeam.name}&id=${selectedTeam._id}&page=${currentPage}`
    );
  };

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  handleDeleteOne = (player) => {
    this.setState({
      deleteDialog: `Are you sure you want to delete ${player.name
        .split("%20%")
        .join(" ")}?`,
      deleteOpen: true,
      selectedPlayer: player,
    });
  };

  handleDeleteTwo = () => {
    const { selectedPlayer: player } = this.state;
    if (player.userID) {
      const captain = this.state.teams.some(
        (t) => t.teamCaptainID === player.userID
      );
      if (captain) {
        const dialog = `${allowables.splitName(player.name)} is captain of ${
          player.teamName
        }. If you delete this player you should select another team captain on the team edit page.`;
        return this.setState({ deleteTwoOpen: true, deleteTwoDialog: dialog });
      }
    }
    this.handleDelete();
  };

  handleDelete = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const { selectedPlayer: player } = this.state;
    const deletedPlayer = await deletePlayer(player._id, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (deletedPlayer.status === 200) {
      ReactGA.event({
        category: "Player",
        action: "delete",
        label: "deleted player from players table",
        nonInteraction: false,
      });
      const players = await getPlayers({
        callback: this.indicateProgress,
        bar: 1,
      });
      if (players.status === 200) {
        this.setState({ players: players.data });
        toast.success("Player deleted");
      } else toast.error(players.data);
    } else toast.error(deletedPlayer.data);
    this.context.setLoading(false);
  };

  handlePageChange = (page) => {
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedTeam,
      page
    );
    this.setState({ currentPage: page });
  };

  handlePreviousPage = () => {
    const currentPage = this.state.currentPage;
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedTeam,
      currentPage - 1
    );
    this.setState({ currentPage: currentPage - 1 });
  };

  handleNextPage = () => {
    const currentPage = this.state.currentPage;
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedTeam,
      currentPage + 1
    );
    this.setState({ currentPage: currentPage + 1 });
  };

  handleTeamSelection = (event) => {
    const team = this.state.teams.find(
      (t) => String(t._id) === event.target.value
    );
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      team,
      1
    );
    this.setState({ selectedTeam: team, searchQuery: "", currentPage: 1 });
  };

  handleSearch = (query) => {
    const resetTeam = { _id: 0, name: "All Teams" };
    this.pushHistoryString(query, this.state.sortColumn, resetTeam, 1);
    this.setState({
      searchQuery: query,
      selectedTeam: resetTeam,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    if (sortColumn.path) {
      this.pushHistoryString(
        this.state.searchQuery,
        sortColumn,
        this.state.selectedTeam,
        this.state.currentPage
      );
      this.setState({ sortColumn });
    }
  };

  handleRecruitPlayer = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const res = await recruitFreeAgent(this.state.recruitPlayer._id, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) {
      toast.success(res.data);
      this.componentDidMount();
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  handleMultiEdit = async (updatedPlayers) => {
    if (updatedPlayers.length === 0)
      return toast.error("Please select at least one player");
    this.context.setLoading(true);
    this.context.setProgress([1, 1]);
    const players = updatedPlayers.map((p) => {
      return { _id: p._id, shirtNumber: p.shirtNumber };
    });
    const res = await bulkUpdateRosterNumbers(players, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) {
      toast.success("Roster numbers updated");
      const playersRes = await getPlayers({
        callback: this.indicateProgress,
        bar: 1,
      });
      if (playersRes.status === 200)
        this.setState({ players: playersRes.data });
      else toast.error(playersRes.data);
      this.context.setLoading(false);
      return true;
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  handleCardClick = (team) => {
    navigateTo(`/players?info&q=${team._id}`, this.props.history, this.context);
  };

  getPageData = () => {
    const { selectedTeam, players, sortColumn, searchQuery } = this.state;
    let filteredPlayers = players;
    if (searchQuery) {
      filteredPlayers = players.filter((p) =>
        allowables
          .splitName(p.name)
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    } else if (
      selectedTeam &&
      selectedTeam._id &&
      selectedTeam._id !== "suspended" &&
      selectedTeam._id !== "freeAgents"
    )
      filteredPlayers = players.filter(
        (p) => (p.teamID?._id || p.teamID) === selectedTeam._id
      );
    else if (selectedTeam && selectedTeam._id === "suspended")
      filteredPlayers = players.filter((p) => p.suspendedFlag > 0);
    else if (selectedTeam && selectedTeam._id === "freeAgents")
      filteredPlayers = players.filter((p) => p.teamName === "Free Agents");

    const sortedPlayers = orderBy(
      filteredPlayers,
      [sortColumn.path],
      [sortColumn.order]
    );
    const playersByPage = paginate(
      sortedPlayers,
      this.state.currentPage,
      this.state.pageSize
    );
    return { playersByPage, sortedPlayers };
  };

  render() {
    const {
      players,
      teams,
      pageSize,
      currentPage,
      searchQuery,
      sortColumn,
      selectedTeam,
      captainOrAdmin,
      deleteDialog,
      deleteOpen,
      deleteTwoOpen,
      deleteTwoDialog,
      recruitOpen,
      recruitPlayer,
    } = this.state;
    const { playersByPage, sortedPlayers } = this.getPageData();
    const user = getCurrentUser();

    return (
      <div>
        {players.length === 0 && user && user.role?.includes("admin") ? (
          <CLink
            path={"/players?q=new"}
            button={true}
            buttonStyle="btn-lg btn-info btn-block"
          >
            Add New Player
          </CLink>
        ) : (
          <div>
            <div className="row">
              <div className="col text-center">
                <NonFormSelect
                  name="_id"
                  label=""
                  options={teams}
                  onChange={this.handleTeamSelection}
                  value={selectedTeam._id}
                  noDefaultOption={true}
                />
              </div>
              <div className="col">
                <p className="header-p">
                  Showing {playersByPage.length} of {sortedPlayers.length}{" "}
                  players. There are {players.length} total players.
                </p>
              </div>
              <div className="col text-center">
                <PlayersButtonLineup
                  user={user}
                  captainOrAdmin={captainOrAdmin}
                  filteredPlayers={sortedPlayers}
                  onMultiEdit={this.handleMultiEdit}
                  search={searchQuery}
                  sort={sortColumn}
                />
              </div>
            </div>
            <TableCardSwitcher
              search={{
                value: searchQuery,
                onChange: (q) => this.setState({ searchQuery: q }),
              }}
              download={{
                name: "players",
                data: players,
                columns: this.downloadColumns,
              }}
              sort={{
                sortColumn,
                onSort: this.handleSort,
                label: "Sort Players",
                options: [
                  { _id: "name", name: "Name" },
                  { _id: "teamName", name: "Team" },
                ],
              }}
              pagination={{
                count: sortedPlayers.length,
                pageSize,
                currentPage,
                onPageChange: this.handlePageChange,
                onPreviousPage: this.handlePreviousPage,
                onNextPage: this.handleNextPage,
              }}
              TableComp={
                <PlayersTable
                  playersByPage={playersByPage}
                  onDelete={this.handleDeleteOne}
                  onSort={this.handleSort}
                  sortColumn={sortColumn}
                  freeAgents={true}
                  onRecruit={(player) =>
                    this.setState({
                      recruitOpen: true,
                      recruitPlayer: player,
                    })
                  }
                  onMoveToFreeAgents={(player) =>
                    this.setState({
                      moveToFreeAgentsOpen: true,
                      recruitPlayer: player,
                    })
                  }
                />
              }
              CardComp={
                <PlayersCardView
                  players={playersByPage}
                  onDelete={this.handleDeleteOne}
                  onRecruit={(player) =>
                    this.setState({
                      recruitOpen: true,
                      recruitPlayer: player,
                    })
                  }
                />
              }
            />
            <QuickLinks
              fullBoard={true}
              org={this.props.org ? this.props.org : null}
            />
            <CustomConfirm
              dialog={deleteDialog}
              isOpen={deleteOpen}
              callback={this.handleDeleteTwo}
              close={this.toggleModal}
              id={"deleteOpen"}
              focused={true}
            />
            <CustomConfirm
              dialog={deleteTwoDialog}
              isOpen={deleteTwoOpen}
              callback={this.handleDelete}
              close={this.toggleModal}
              id={"deleteTwoOpen"}
              focused={true}
            />
            <CustomConfirm
              dialog={`Recruit player ${allowables.splitName(
                recruitPlayer?.name
              )} to your team?`}
              isOpen={recruitOpen}
              callback={this.handleRecruitPlayer}
              close={this.toggleModal}
              id={"recruitOpen"}
              focused={true}
              yesNo={true}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Players;

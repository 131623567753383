import React, { useContext } from "react";

import TeamLogo from "../common/pageComponents/teamLogo";
import allowables from "../../utils/allowables";
import IconRender from "../common/icons/iconRender";
import { getTextColor } from "ultimatescoreboard-shared";
import { canReferee, isAdmin } from "../../utils/permissionsUtil";
import { getCurrentUser } from "../../services/userService";
import TooltipButton from "../common/tooltip/tooltipButton";
import { navigateTo } from "../common/customs/customLinks";
import HeaderContext from "../../context/headerContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import OrgContext from "../../context/orgContext";

const MatchesCardView = ({
  matches,
  onClick,
  liveScorecards,
  onDelete,
  onSelfAssign,
}) => {
  const org = useContext(OrgContext);
  const header = useContext(HeaderContext);
  const history = useHistory();

  if (!matches.length)
    return (
      <p className="cardview-card text-center">
        There are no scorecards to display
      </p>
    );

  const teamOrder = allowables.teams(matches[0].sport);

  const addMatchInfo = (match) => {
    match.winner =
      match.homeTeamGoals > match.awayTeamGoals
        ? "home"
        : match.awayTeamGoals > match.homeTeamGoals
        ? "away"
        : match.homeTeamPKs > match.awayTeamPKs
        ? "home"
        : match.awayTeamPKs > match.homeTeamPKs
        ? "away"
        : "tie";
  };

  const user = getCurrentUser();

  const renderTeam = (curMatch, team) => {
    let match = { ...curMatch };
    addMatchInfo(match);
    const showScore = match.matchComplete || liveScorecards;
    let score = (
      <div style={{ flex: "15%" }} className="align-self-center">
        {match[team + (liveScorecards ? "TeamScore" : "TeamGoals")]}
      </div>
    );
    if (match.homeTeamPKs || match.awayTeamPKs) {
      score = (
        <div style={{ flex: "15%" }} className="align-self-center">
          {match[team + (liveScorecards ? "TeamScore" : "TeamGoals")]}{" "}
          <small>({match[team + "TeamPKs"]})</small>
        </div>
      );
    }
    const fontWeight =
      match.matchAccepted && match.winner === team ? "bold" : "regular";
    const teamColor1 = match[team + "TeamLogo"]?.teamColor1;
    const teamColor2 = match[team + "TeamLogo"]?.teamColor2;
    const color = getTextColor(teamColor1);

    return (
      <div
        className="cardview-text cardview-pop"
        style={{ color, backgroundColor: teamColor1 }}
      >
        <div
          style={{
            display: "flex",
            fontWeight,
          }}
        >
          <div style={{ flex: "10%" }} className="align-self-center">
            {match[team + "TeamLogo"] && (
              <TeamLogo
                team={match[team + "TeamLogo"]}
                name={match[team + "TeamName"]}
                size={50}
              />
            )}
          </div>
          <div
            style={{ flex: "65%", marginLeft: 5 }}
            className="align-self-center"
          >
            {match[team + "TeamName"]}
          </div>
          {showScore ? (
            score
          ) : (
            <div style={{ flex: "10%" }} className="align-self-center" />
          )}
          <div
            className="align-self-center"
            style={{ flex: "10%", marginLeft: 5 }}
          >
            {teamColor2 ? (
              <div
                className="color-square"
                style={{
                  backgroundColor: teamColor2,
                  width: "50%",
                  margin: 0,
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderDate = (match) => {
    const date = allowables.dateTime(match.dateTime);
    return (
      <div className="cardview-text cardview-pop font-weight-bold">
        <IconRender name="calendar" className="icon-raised" />
        &nbsp;{date?.date || "Not scheduled"}&nbsp;{date?.time}
      </div>
    );
  };

  const renderMoreInfo = (match) => {
    return (
      <div>
        <div className="cardview-text row">
          <div className="col">
            <IconRender name="division" className="icon-raised" />
            &nbsp;{match.divisionName}
          </div>
          <div className="col">
            <IconRender name="star" className="icon-raised" />
            &nbsp;{match.type}
          </div>
        </div>
        {match.fieldName && (
          <div className="col">
            <IconRender name="map" className="icon-raised" />
            &nbsp;
            {match.fieldName}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="cardview">
      {matches.map((m, idx) => {
        return (
          <div key={idx}>
            <div className="cardview-card clickable">
              <div onClick={onClick ? () => onClick(m) : null}>
                {renderDate(m)}
                {renderTeam(m, teamOrder[0])}
                {renderTeam(m, teamOrder[1])}
                {renderMoreInfo(m)}
              </div>
              <div className="row">
                {org?.misc?.allowMatchSelfAssignment &&
                org?.misc?.liveScorecards &&
                canReferee(user) &&
                m.refereeID?._id !== user._id ? (
                  <div className="col text-center">
                    <TooltipButton
                      className="btn btn-sm btn-light m-1"
                      onClick={() => onSelfAssign(m)}
                      tooltipText={`Assign this scorecard to me`}
                      tooltipDirection="right"
                    >
                      <IconRender name="assign" />
                    </TooltipButton>
                  </div>
                ) : null}
                {isAdmin(user) && onDelete ? (
                  <React.Fragment>
                    <div className="col text-center">
                      <TooltipButton
                        className="btn btn-sm btn-secondary m-1"
                        onClick={() =>
                          navigateTo(`/matches?q=${m._id}`, history, header)
                        }
                        tooltipText={`Edit this scorecard`}
                        tooltipDirection="right"
                      >
                        <IconRender name="edit" />
                      </TooltipButton>
                    </div>
                    <div className="col text-center">
                      <TooltipButton
                        className="btn btn-sm btn-dark m-1"
                        onClick={() => onDelete(m)}
                        tooltipText={`Delete this scorecard`}
                        tooltipDirection="left"
                      >
                        <IconRender name="delete" />
                      </TooltipButton>
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
            <div className="card-separator" />
          </div>
        );
      })}
    </div>
  );
};

export default MatchesCardView;

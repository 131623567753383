export const offsets = { text: 10, lines: 5 };

export const getTextX = (textAnchor, width) => {
  return textAnchor === "start"
    ? offsets.text
    : textAnchor === "end"
    ? width - offsets.text
    : width / 2;
};
export const getLineX = (textAnchor, width) => {
  return textAnchor === "start"
    ? offsets.lines
    : textAnchor === "end"
    ? width - offsets.lines
    : width / 2;
};

const dummyMatch = (sport) => ({
  dummyMatch: true,
  round: 0,
  sport,
  homeTeamName: "",
  awayTeamName: "",
});

export const separateAndSplit = (allRounds, bracket, matches, split) => {
  let leftMatches = [];
  let rightMatches = [];
  let allMatches = [];
  let roundsToShow = allRounds[bracket];
  const sortedMatches = [
    ...matches.sort((a, b) => a.matchNumber - b.matchNumber),
  ];
  roundsToShow.forEach((r) => {
    const roundMatches = [...sortedMatches.filter((m) => m.round === r)];
    if (r === 1) {
      const prelimMatchMap = {};
      matches.forEach((m) => {
        if (m.round === 0) prelimMatchMap[m.matchNumber] = m;
      });
      // add prelim matches, must be sorted differently and add dummy matches
      let prelims = allRounds["prelim"];
      if (prelims.length) {
        const prelimPositionArr = [];
        sortedMatches.forEach((match, idx) => {
          if (match.round === 1) {
            const homeSourceMatchNumber =
              match.metadata?.homeTeamFromMatch?.number;
            const awaySourceMatchNumber =
              match.metadata?.awayTeamFromMatch?.number;

            if (homeSourceMatchNumber) {
              prelimPositionArr.push(prelimMatchMap[homeSourceMatchNumber]);
            } else {
              prelimPositionArr.push(dummyMatch(matches[0].sport));
            }
            if (awaySourceMatchNumber) {
              prelimPositionArr.push(prelimMatchMap[awaySourceMatchNumber]);
            } else {
              prelimPositionArr.push(dummyMatch(matches[0].sport));
            }
          }
        });
        allMatches.push(prelimPositionArr);
        const splitPrelims = [...prelimPositionArr];
        leftMatches.push(splitPrelims.splice(0, prelimPositionArr.length / 2));
        rightMatches.push(splitPrelims);
      }
    }
    allMatches.push(roundMatches);
    const splitMatches = [...roundMatches];
    leftMatches.push(splitMatches.splice(0, roundMatches.length / 2));
    rightMatches.push(splitMatches);
  });
  leftMatches = leftMatches.slice(0, leftMatches.length - 1);
  if (split) return [...leftMatches, ...rightMatches.reverse()];
  else return allMatches;
};

export const getTeamNameYPlacement = (verticalPosition, height) => {
  return verticalPosition === 0 ? 20 : height - 10;
};

export const matchHeight = 100;

export function getRoundName(roundMatchLength, orientation, isFinal) {
  let actualRoundMatches =
    roundMatchLength * (orientation === "portrait" || isFinal ? 1 : 2);
  let name = "";
  switch (actualRoundMatches) {
    case 1:
      name = "Final";
      break;
    case 2:
      name = "Semi-Final";
      break;
    case 4:
      name = "Quarter-Final";
      break;
    default:
      name = "Round of " + actualRoundMatches;
      break;
  }
  return name;
}

export const getDataUrl = (image) => {
  return new Promise((resolve, reject) => {
    var request = new XMLHttpRequest();
    request.open("GET", image.href.baseVal, true);
    request.responseType = "blob";
    request.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload = function (e) {
        resolve(e.target.result);
      };
    };
    request.send();
  });
};

import React, { useEffect, useState } from "react";
import Table from "../common/table/table";
import HorizontalButtons from "../common/dataSorting/horizontalButtons";
import { isAdmin } from "../../utils/permissionsUtil";
import { getCurrentUser } from "../../services/userService";
import NonFormSelect from "../common/form/nonFormSelect";
import ExcelDownload from "../common/pageComponents/excelDownload";
import MiniHeader from "../common/pageComponents/miniHeader";
import { CLink } from "../common/customs/customLinks";
import WarningHeader from "../common/pageComponents/warningHeader";

const standardColumns = [
  {
    path: "name",
    label: "Recipient",
    content: (vote) => (
      <CLink path={`/players?info&q=${vote.playerID}`}>
        <b>{vote.name}</b>
      </CLink>
    ),
  },
  {
    path: "teamName",
    label: "Team",
    content: (vote) => (
      <CLink path={`/teams?info&q=${vote.teamID}`}>{vote.teamName}</CLink>
    ),
  },
];

const MvpVotesTable = ({
  mvpVoting,
  teams,
  showDetail,
  setVotedCategories,
  allowedVotes,
}) => {
  const [selectedTable, setSelectedTable] = useState("leaguewide");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [mappedVotes, setMappedVotes] = useState([]);
  const [rawVotes, setRawVotes] = useState([]);

  const [sortColumn, setSortColumn] = useState({ path: "name", order: "asc" });
  const user = getCurrentUser();

  useEffect(() => {
    let newMappedVotes = {};
    let categories = [];
    let allVotes = [];
    teams.forEach((team, teamIdx) => {
      team.mvpVotes?.forEach((vote, idx) => {
        allVotes.push({
          ...vote,
          teamName: team.name,
          teamID: team._id,
          key: team._id + teamIdx + idx,
        });
        newMappedVotes[vote.playerID] = {
          ...vote,
          teamID: team._id,
          teamName: team.name,
          [vote.category]:
            (newMappedVotes[vote.playerID]?.[vote.category] || 0) + 1,
        };

        if (!categories.find((cat) => cat.path === vote.category))
          categories.push({ path: vote.category, label: vote.category });
      });
    });

    let voteData = [];

    for (let player in newMappedVotes) {
      voteData.push(newMappedVotes[player]);
    }

    setRawVotes(allVotes);
    setSortColumn({ path: categories[0]?.path || "name", order: "desc" });
    setMappedVotes(voteData);
    setVotedCategories(categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  const getColumns = (type) => {
    let categories = [];
    mvpVoting.categories.forEach((cat) => {
      let col = { path: cat.name, label: cat.name };
      if (
        (type === "internal" && cat.canVoteForOwnTeam) ||
        (type === "external" && cat.canVoteForOtherTeams)
      )
        categories.push(col);
    });
    return [...standardColumns, ...categories];
  };

  const onSort = (newSortColumn) => {
    setSortColumn(newSortColumn);
  };

  const getPageData = (type) => {
    let cur = [...mappedVotes];

    const filteredVotes = cur.filter((vote) => {
      let filterOut = false;
      if (
        selectedTable === "internal" &&
        selectedTeam &&
        selectedTeam !== "all"
      ) {
        if (vote.teamID !== selectedTeam) filterOut = true;
      }
      return !filterOut && vote.type === type;
    });
    const sortedVotes = filteredVotes.sort((a, b) => {
      let valueA = a[sortColumn.path] || 0;
      let valueB = b[sortColumn.path] || 0;

      if (typeof valueA === "string") {
        return sortColumn.order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
      return sortColumn.order === "asc" ? valueA - valueB : valueB - valueA;
    });

    return sortedVotes;
  };

  const rawVoteDownloadColumns = [
    "voterName",
    "name",
    "teamName",
    "category",
    "type",
  ];

  const rawVoteColumns = [
    {
      path: "download",
      label: (
        <ExcelDownload
          data={rawVotes}
          columns={rawVoteDownloadColumns}
          dataName="Vote Detail"
        />
      ),
    },
    {
      path: "voterName",
      label: "Voter",
      content: (vote) => (
        <CLink path={`/players?info&q=${vote.voterPlayerID}`}>
          {vote.voterName}
        </CLink>
      ),
    },
    ...standardColumns,
    { path: "category", label: "Category" },
    { path: "type", label: "Type" },
  ];

  return !rawVotes.length ? (
    <WarningHeader>There have been no votes cast yet</WarningHeader>
  ) : showDetail ? (
    <div>
      <MiniHeader>Raw Vote Detail</MiniHeader>
      <Table
        columns={rawVoteColumns}
        data={rawVotes}
        keyProperty="key"
        tableClass="table-custom"
        headerClass="thead-custom"
        bodyClass="tbody-custom"
        highlightOnHover={true}
      />
    </div>
  ) : (
    <div>
      {isAdmin(user) ? (
        <HorizontalButtons
          buttons={["leaguewide", "internal"]}
          selectedType={selectedTable}
          onClick={setSelectedTable}
        />
      ) : null}
      {allowedVotes?.otherTeam && selectedTable === "leaguewide" ? (
        <Table
          columns={getColumns("external")}
          data={getPageData("external")}
          sortColumn={sortColumn}
          onSort={onSort}
          keyProperty={"name"}
          tableClass="table-custom"
          headerClass="thead-custom"
          bodyClass="tbody-custom"
          highlightOnHover={true}
        />
      ) : null}
      {allowedVotes?.ownTeam && selectedTable === "internal" ? (
        <div>
          <NonFormSelect
            name="teamName"
            label="Select Team:&nbsp;"
            options={[{ _id: "all", name: "All Teams" }, ...teams]}
            onChange={(event) => {
              setSelectedTeam(event.target.value);
            }}
            noDefaultOption={true}
            value={selectedTeam || ""}
          />
          <Table
            columns={getColumns("internal")}
            data={getPageData("internal")}
            sortColumn={sortColumn}
            onSort={onSort}
            keyProperty={"name"}
            tableClass="table-custom"
            headerClass="thead-custom"
            bodyClass="tbody-custom"
            highlightOnHover={true}
          />
        </div>
      ) : null}
    </div>
  );
};

export default MvpVotesTable;

import React, { useContext } from "react";
import { getCurrentUser } from "../../services/userService";
import WarningHeader from "../common/pageComponents/warningHeader";
import { navigateTo } from "../common/customs/customLinks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HeaderContext from "../../context/headerContext";

const MvpVotingStatus = ({ org }) => {
  const history = useHistory();
  const header = useContext(HeaderContext);
  const user = getCurrentUser();
  const canVote =
    user &&
    user.role?.includes("player") &&
    user.teamID &&
    org?.misc?.mvpVoting?.categories?.length &&
    org?.misc?.mvpVoting?.categories.find((cat) => {
      return cat.canVoteForOwnTeam || user.role.includes("captain");
    });

  return canVote ? (
    <div
      className="clickable"
      onClick={() => navigateTo("/awards", history, header)}
    >
      <WarningHeader overrideIconName="mvp">
        Have you voted for League awards?
      </WarningHeader>
    </div>
  ) : null;
};

export default MvpVotingStatus;

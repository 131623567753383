import React, { useState, useEffect, useContext } from "react";
import { getTeams, voteForMvp } from "../../services/teamService";
import { getPlayersByTeam } from "../../services/playerService";
import HeaderContext from "../../context/headerContext";
import toast from "../../utils/toast";
import { getCurrentUser } from "../../services/userService";
import MiniHeader from "../common/pageComponents/miniHeader";
import PlayersCardView from "../players/playersCardView";
import BasicModal from "../common/pageComponents/basicModal";
import { popupStyle } from "../../utils/styleUtil";
import IconRender from "../common/icons/iconRender";
import allowables from "../../utils/allowables";
import TeamsCardView from "../teams/teamsCardView";
import CustomConfirm from "../common/customs/customConfirm";
import WarningHeader from "../common/pageComponents/warningHeader";

const MvpVoting = ({ mvpVoting, allowedVotes }) => {
  const { setLoading } = useContext(HeaderContext);
  const [teams, setTeams] = useState([]);
  const [usersTeam, setUsersTeam] = useState(null);
  const [players, setPlayers] = useState([]);
  const [voteOpen, setVoteOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [voteInfo, setVoteInfo] = useState({});
  const [rawVotes, setRawVotes] = useState([]);
  const [singleVotes, setSingleVotes] = useState([]);
  const [voteSwitchOpen, setVoteSwitchOpen] = useState(false);
  const [voteSwitchInfo, setVoteSwitchInfo] = useState({
    category: null,
    from: null,
    to: null,
  });

  const user = getCurrentUser();

  const getVoteData = async () => {
    setLoading(true);

    const teamsRes = await getTeams(null, true);

    if (teamsRes.status === 200) {
      const myTeam = teamsRes.data.find((t) => t._id === user.teamID);

      let newVoteInfo = {};
      let displayTeams = [];
      let canVoteOutsideDivision;
      let singleVoteCategories = [];
      let newSingleVotes = [];
      let allVotes = [];
      mvpVoting.categories.forEach((cat) => {
        if (cat.canVoteOutsideDivision) canVoteOutsideDivision = true;
        if (cat.singleVote && cat.canVoteForOtherTeams)
          singleVoteCategories.push(cat.name);
      });
      teamsRes.data.forEach((team) => {
        let newVotes = [];
        team.mvpVotes?.forEach((vote) => {
          if (vote.voterPlayerID === user.playerID) {
            let thisVote = { ...vote, teamName: team.name };
            allVotes.push(thisVote);
            newVotes.push(thisVote);
            if (singleVoteCategories.includes(vote.category)) {
              newSingleVotes.push(thisVote);
            }
          }
        });
        newVoteInfo[team._id] = newVotes;
        if (myTeam?._id === team._id) {
          setUsersTeam(team);
        } else {
          if (
            team.divisionID._id === myTeam?.divisionID._id ||
            canVoteOutsideDivision
          ) {
            displayTeams.push(team);
          }
        }
      });
      setRawVotes(allVotes);
      setSingleVotes(newSingleVotes);
      setTeams(displayTeams);
      setVoteInfo(newVoteInfo);
    } else toast.error(teamsRes.data);

    setLoading(false);
  };

  useEffect(() => {
    getVoteData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenTeam = async (team, opened) => {
    if (opened) return setPlayers([]);
    setLoading(true);
    const playersRes = await getPlayersByTeam(team._id);
    if (playersRes.status === 200) {
      setPlayers(playersRes.data);
      handleScrollToTeam(team.name);
    } else toast.error(playersRes.data);
    setLoading(false);
  };

  const handleScrollToTeam = (teamName) => {
    const header = document.querySelector("#" + teamName.replaceAll(" ", "_"));
    if (header?.scrollIntoView)
      header.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleOpenVote = (player) => {
    setVoteOpen(true);
    setSelectedPlayer(player);
  };

  const renderTeamSection = (teams) => {
    if (!teams.length || !teams[0]) return null;
    return (
      <TeamsCardView
        teams={teams}
        isSpectate={true}
        bottomItems={[
          {
            content: (team) => {
              const categories = mvpVoting.categories.filter((cat) =>
                canVoteThisCategory(cat, team._id)
              );
              return (
                <div
                  className="pop-box clickable"
                  onClick={() =>
                    handleOpenTeam(team, players[0]?.teamID === team._id)
                  }
                >
                  {categories.map((cat, catIdx) => {
                    return (
                      <div key={catIdx}>
                        {renderVote(
                          cat,
                          team._id,
                          null,
                          catIdx === categories.length - 1,
                          "Click to view players"
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            },
          },
          {
            content: (team) => {
              const opened = players[0]?.teamID === team._id;
              return opened ? (
                <div>
                  <p className="text-center">
                    <b>Choose a player</b>
                  </p>
                  <PlayersCardView
                    players={players}
                    isSpectate={true}
                    onVote={handleOpenVote}
                    hideBonusFields={true}
                  />
                </div>
              ) : null;
            },
          },
        ]}
      />
    );
  };

  const getThisVote = (category, teamID) => {
    if (!category.singleVote)
      return voteInfo[teamID]?.find((v) => v.category === category.name);
    else return rawVotes.find((vote) => vote.category === category.name);
  };

  const renderVote = (category, teamID, providedVote, isLast, changeText) => {
    const vote = providedVote || getThisVote(category, teamID);
    if (!providedVote && !canVoteThisCategory(category, teamID)) return null;
    return (
      <div>
        <span>
          <h6>
            <IconRender name="mvp" /> <b>{category.name}</b>
          </h6>
          <small>Vote type:</small>{" "}
          <b>
            {category.singleVote && category.canVoteForOtherTeams
              ? "League"
              : "Team"}
          </b>{" "}
          -{" "}
          {category.canVoteForOwnTeam && category.canVoteForOtherTeams
            ? "All Teams"
            : category.canVoteForOwnTeam
            ? "Your Team Only"
            : "All Other Teams"}
          {category.canVoteForOtherTeams
            ? category.canVoteOutsideDivision
              ? " in League"
              : " in Division"
            : null}
          <br />
          {vote ? (
            <span>
              <small>Your pick:</small> <b>{vote.name}</b>
              {category.singleVote ? <i> - {vote.teamName}</i> : null}
            </span>
          ) : (
            <small>Click to cast vote</small>
          )}
          {!providedVote && vote && changeText ? (
            <small>
              <br />
              {changeText}
            </small>
          ) : null}
        </span>
        {isLast ? null : <hr className="custom" />}
      </div>
    );
  };

  const canVoteThisCategory = (category, teamID) => {
    const myTeam = teamID === user.teamID;
    const team = teams.find((t) => t._id === teamID);
    return (
      (myTeam && category.canVoteForOwnTeam) ||
      (!myTeam &&
        category.canVoteForOtherTeams &&
        (category.canVoteOutsideDivision ||
          team?.divisionID._id === usersTeam?.divisionID._id))
    );
  };

  const handleVote = async (category, confirmed) => {
    const existingVote = getThisVote(category, selectedPlayer.teamID);
    if (
      !confirmed &&
      existingVote &&
      existingVote.playerID !== selectedPlayer._id
    ) {
      setVoteSwitchInfo({ category, from: existingVote, to: selectedPlayer });
      setVoteSwitchOpen(true);
      return setVoteOpen(false);
    }

    setLoading(true);
    const res = await voteForMvp(selectedPlayer.teamID, {
      name: selectedPlayer?.name,
      playerID: selectedPlayer?._id,
      category: category.name,
    });
    if (res.status === 200) {
      setVoteOpen(false);
      setPlayers([]);
      handleScrollToTeam(selectedPlayer?.teamName);
      toast.success(
        `Vote Successful for ${allowables.splitName(selectedPlayer?.name)}`
      );
      return getVoteData();
    } else toast.error(res.data);
    setLoading(false);
  };

  return (
    <div className="centered-small-input-area">
      {singleVotes.length ? (
        <div>
          <MiniHeader>Your Leaguewide Votes</MiniHeader>
          <div className="form-divided-section">
            <p className="text-center">
              These categories allowed you to vote for a single player across
              the league. Change your vote by finding the player below.
            </p>
            <div className="pop-box">
              {singleVotes.map((vote, idx) => {
                return (
                  <div key={idx}>
                    {renderVote(
                      { name: vote.category, singleVote: true },
                      null,
                      vote,
                      idx === singleVotes.length - 1
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
      {allowedVotes.ownTeam ? (
        <div>
          <MiniHeader>Vote for Your Team</MiniHeader>
          {renderTeamSection([usersTeam])}
        </div>
      ) : !user.role.includes("captain") ? (
        <WarningHeader>
          There are no categories for you to vote on
        </WarningHeader>
      ) : null}

      {allowedVotes.otherTeam && user?.role?.includes("captain") ? (
        <div>
          <MiniHeader>Vote for Players Across the League</MiniHeader>
          {renderTeamSection(teams)}
        </div>
      ) : null}

      <CustomConfirm
        dialog={
          <span>
            Switch vote for <b>{voteSwitchInfo.category?.name}</b> from{" "}
            {voteSwitchInfo.from?.name} to{" "}
            <i>{allowables.splitName(voteSwitchInfo.to?.name)}</i>?
          </span>
        }
        isOpen={voteSwitchOpen}
        callback={() => handleVote(voteSwitchInfo.category, true)}
        close={() => setVoteSwitchOpen(false)}
        negativeCallback={() => {
          setVoteOpen(true);
        }}
        alsoRunOnClose={() => {
          setVoteOpen(true);
        }}
      />
      <BasicModal
        isOpen={voteOpen}
        onClose={setVoteOpen}
        style={{ content: { ...popupStyle.content, minWidth: "50%" } }}
      >
        <PlayersCardView players={[selectedPlayer]} isSpectate={true} />
        <div>
          <MiniHeader>Current Votes for {selectedPlayer?.teamName}</MiniHeader>
          <div className="form-divided-section">
            {mvpVoting.categories.map((cat, catIdx) => {
              if (!canVoteThisCategory(cat, selectedPlayer?.teamID))
                return null;
              return (
                <div
                  key={catIdx}
                  className="clickable"
                  onClick={() => handleVote(cat)}
                >
                  {renderVote(
                    cat,
                    selectedPlayer?.teamID,
                    null,
                    catIdx === mvpVoting.categories.length - 1,
                    "Click to change vote"
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <br />
        <br />
      </BasicModal>
    </div>
  );
};

export default MvpVoting;

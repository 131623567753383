import React, { useContext, useEffect, useState } from "react";
import HeaderContext from "../../context/headerContext";
import { getTeams, resetMvpVotes } from "../../services/teamService";
import toast from "../../utils/toast";
import MvpVotesTable from "./mvpVotesTable";
import TooltipButton from "../common/tooltip/tooltipButton";
import IconRender from "../common/icons/iconRender";
import BasicModal from "../common/pageComponents/basicModal";
import { popupStyle } from "../../utils/styleUtil";
import CustomConfirm from "../common/customs/customConfirm";
import MiniHeader from "../common/pageComponents/miniHeader";

const MvpAdmin = ({ mvpVoting, allowedVotes }) => {
  const { setLoading } = useContext(HeaderContext);
  const [teams, setTeams] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [votedCategories, setVotedCategories] = useState([]);
  const [deleteVotesOpen, setDeleteVotesOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState("");

  const getData = async () => {
    setLoading(true);
    const res = await getTeams(null, true);
    if (res.status === 200) {
      setTeams(res.data);
    } else toast.error(res.data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveVotes = async () => {
    setLoading(true);
    const res = await resetMvpVotes(confirmOpen);
    if (res.status === 200) {
      toast.success(res.data);
      setDeleteVotesOpen(false);
      getData();
    } else toast.error(res.data);
    setLoading(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col text-left">
          <TooltipButton
            className={`btn btn-sm btn-${showDetail ? "info" : "light"}`}
            onClick={() => setShowDetail(!showDetail)}
            tooltipText={showDetail ? "Show vote tallies" : "Show vote detail"}
            tooltipDirection="right"
            testId={"show-raw-votes"}
          >
            <IconRender name={showDetail ? "table" : "info"} />
          </TooltipButton>
        </div>
        <div className="col text-right">
          <TooltipButton
            className="btn btn-sm btn-dark"
            onClick={() => setDeleteVotesOpen(true)}
            tooltipText="Reset Votes"
            tooltipDirection="left"
            testId={"reset-votes"}
          >
            <IconRender name="reset" />
          </TooltipButton>
        </div>
      </div>
      <MvpVotesTable
        mvpVoting={mvpVoting}
        teams={teams}
        showDetail={showDetail}
        setVotedCategories={setVotedCategories}
        allowedVotes={allowedVotes}
      />
      <BasicModal
        isOpen={deleteVotesOpen}
        onClose={setDeleteVotesOpen}
        style={popupStyle}
      >
        <MiniHeader>Remove All Votes by Category</MiniHeader>
        <div className="form-divided-section">
          {votedCategories.map((cat, catIdx) => (
            <div key={catIdx}>
              <button
                className="btn btn-info btn-block m-1"
                onClick={() => setConfirmOpen(cat.label)}
              >
                {cat.label}
              </button>
            </div>
          ))}
        </div>
      </BasicModal>
      <CustomConfirm
        dialog={`Are you sure you want to remove all votes for ${confirmOpen}?%
        This cannot be undone`}
        split="%"
        isOpen={!!confirmOpen}
        callback={handleRemoveVotes}
        close={() => setConfirmOpen("")}
      />
    </div>
  );
};

export default MvpAdmin;

import React from "react";
import IconRender from "./iconRender";

const BoolVis = ({ bool, overrideClass }) => {
  return (
    <IconRender
      name={bool ? "checkedbox" : "circle"}
      className={overrideClass || "icon-mobile"}
    />
  );
};

export default BoolVis;

import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import toast from "../../utils/toast";
import orderBy from "lodash/orderBy";
import CustomPrompt from "../common/customs/customPrompt";
import CustomAlert from "../common/customs/customAlert";
import HeaderContext from "../../context/headerContext";
import { navigateBack, CLink, navigateTo } from "../common/customs/customLinks";
import WarningHeader from "../common/pageComponents/warningHeader";
import InfoComponent from "../common/info/infoComponent";
import Avatar from "../common/pageComponents/avatarEditor";
import ImageHeader from "../common/pageComponents/imageHeader";

class PlayerForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      firstName: "",
      lastName: "",
      teamName: "",
      shirtNumber: "",
      email: "",
    },
    teams: [],
    division: {},
    errors: {},
    baseState: {
      firstName: "",
      lastName: "",
      teamName: "",
      shirtNumber: "",
      email: "",
    },
    apiError: "",
    source: "edit",
    captain: false,
    captainID: null,
    emailOpen: false,
    emailSent: "",
    incrementSuspensionsOpen: false,
    link: "",
    org: {},
    imageEditOpen: false,
  };

  schema = {
    firstName: Joi.string().required().max(50).label("Player First Name"),
    lastName: Joi.string().required().max(50).label("Player Last Name"),
    teamName: Joi.string().required().allow("").label("Team Name"),
    shirtNumber: Joi.number()
      .integer()
      .min(-1)
      .max(999)
      .allow("")
      .label(
        this.props.org?.sport.toLowerCase().includes("diamond")
          ? "Roster Position"
          : "Shirt Number"
      ),
    email: Joi.string()
      .email()
      .optional()
      .allow("")
      .min(5)
      .max(255)
      .label("Email Address"),
    suspendedFlag: Joi.number()
      .integer()
      .allow("")
      .min(0)
      .label("Suspended Matches"),
    runningYellows: Joi.number()
      .integer()
      .allow("")
      .min(0)
      .label("Running Yellows"),
  };

  loadData = async (forceEdit) => {
    this.context.setLoading(true);
    this.context.setProgress([1, 1, 1]);
    const user = this.props.getCurrentUser();
    if (this.props.org) {
      this.setState({ org: this.props.org });
      const teamsResponse = await this.props.getTeams({
        callback: this.indicateProgress,
        bar: 0,
      });
      if (teamsResponse.status === 200) {
        let forcedPlayer = {};
        let forcedTeamList = [];
        let forcedTeamName = "";
        let forcedTeamID = null;
        let captain = false;
        let captainID = null;
        let teams = [
          { _id: "freeAgents", name: "Free Agents" },
          ...orderBy(teamsResponse.data, ["name"], ["asc"]),
        ];
        const { playerID } = this.props;

        // if user is team captain then they can only assign players to that one team
        teams.forEach((t) => {
          if (t.teamCaptainID && t.teamCaptainID._id === user._id) {
            forcedTeamName = t.name;
            forcedTeamID = t._id;
            captain = true;
            forcedTeamList.push(t);
          }
        });

        if (playerID === "new") {
          if (captain) {
            forcedPlayer.teamName = forcedTeamName;
            this.context.setLoading(false);
            return this.setState({
              data: this.mapToViewModel(forcedPlayer),
              teams: forcedTeamList,
              captain,
            });
          } else if (user.role.includes("admin")) {
            this.context.setLoading(false);
            return this.setState({ teams });
          } else return navigateBack(this.props.history, this.context);
        }

        const response = await this.props.getPlayer(playerID, {
          callback: this.indicateProgress,
          bar: 1,
        });
        if (response.status === 200 || playerID === "new") {
          if (response.data.teamID) {
            teams.forEach((t) => {
              if (response.data.teamID && t._id === response.data.teamID._id)
                captainID = t.teamCaptainID ? t.teamCaptainID._id : null;
            });
            const divisionRes = await this.props.getDivision(
              response.data.teamID.divisionID,
              {
                callback: this.indicateProgress,
                bar: 2,
              }
            );
            if (divisionRes.status === 200) {
              this.setState({ division: divisionRes.data });
            }
          }
          let source = forceEdit ? "edit" : this.props.source;

          if (!forceEdit && this.props.location.search.includes("info"))
            source = "info";
          else if (
            (!response.data.teamID ||
              forcedTeamID !== response.data.teamID._id) &&
            !user.role.includes("admin")
          )
            return navigateBack(this.props.history, this.context);
          else if (captain) {
            teams = forcedTeamList;
          }
          // if player is team captain then they cannot be moved between teams
          if (user.role.includes("admin")) {
            if (
              response.data.teamID &&
              response.data.userID &&
              response.data.teamID.teamCaptainID === response.data.userID._id
            )
              teams = teams.filter((t) => t._id === response.data.teamID._id);
          }
          response.data.firstName = response.data.name.split("%20%")[0];
          response.data.lastName = response.data.name.split("%20%")[1] || "";

          this.setState({
            data: this.mapToViewModel(response.data),
            baseState: response.data,
            source,
            teams,
            captain,
            captainID,
          });
        } else return this.props.history.replace("/not-found");
      } else toast.error(teamsResponse.data);
      this.context.setLoading(false);
    }
  };

  async componentDidMount() {
    this.loadData();
  }

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  mapToViewModel(player) {
    return {
      firstName: player.firstName || "",
      lastName: player.lastName || "",
      teamName: player.teamName || "",
      shirtNumber: player.shirtNumber || "",
      suspendedFlag: player.suspendedFlag || 0,
      runningYellows: player.runningYellows || 0,
    };
  }

  getInfoFields = () => {
    const sport = this.state.baseState.sport.toLowerCase();
    const fields = [
      { name: "Email", property: "userID.email", type: "email" },
      {
        name: "Team",
        property: "teamName",
        link:
          this.state.baseState.teamName !== "Free Agents"
            ? `/teams?info&q=${this.state.baseState.teamID._id}`
            : null,
      },
      {
        name:
          sport === "soccer"
            ? "Shirt Number"
            : sport === "diamond sports"
            ? "Roster Position"
            : "Roster Number",
        property: "shirtNumber",
      },
      { name: "Unserved Suspensions", property: "suspendedFlag" },
    ];
    if (sport === "soccer")
      fields.push({
        name: "Accumulated Yellow Cards",
        property: "runningYellows",
      });
    return fields;
  };

  doSubmit = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const playerID = this.props.playerID;
    const user = this.props.getCurrentUser();
    let data = { ...this.state.data };
    data.orgID = user.orgID;
    data.orgName = user.orgName;
    data.name = data.firstName + "%20%" + data.lastName;
    const email = data.email ? data.email.toLowerCase() : "";
    delete data.firstName;
    delete data.lastName;
    delete data.email;
    let teamID;
    this.state.teams.forEach((team) => {
      if (team.name === data.teamName) teamID = team._id;
    });
    if (!teamID) {
      this.context.setLoading(false);
      return toast.error("Team Name is required.");
    }
    if (playerID === "new") {
      data.runningYellows = 0;
      data.suspendedFlag = 0;
    }
    data.teamID = teamID;
    data.sport = this.state.org.sport;
    const response = await this.props.savePlayer(playerID, data, email, {
      callback: this.indicateProgress,
      bar: 0,
    });
    if (response.status === 200) return this.props.history.replace("/players");
    this.setState({ apiError: response.data });
    toast.error(this.state.apiError);
    this.context.setLoading(false);
  };

  handleSwitchToEditMode = () => {
    const editPath =
      this.props.history.location.pathname +
      this.props.history.location.search.replace("info&", "");
    navigateTo(editPath, this.props.history, this.context);
    this.loadData(true);
  };

  sendRegistrationEmail = async (email) => {
    const ex = Joi.validate(
      { email },
      { email: Joi.string().required().email().label("Email") }
    );
    if (ex.error) return toast.error(ex.error.details[0].message);
    this.context.setLoading(true);
    this.context.setProgress([1]);

    const response = await this.props.sendLink(
      email,
      { _id: this.state.baseState._id },
      { callback: this.indicateProgress, bar: 0 }
    );
    if (response.status === 200) {
      toast.success(response.data);
      this.setState({ copiedLink: true, emailSent: email });
    } else toast.error(response.data);
    this.context.setLoading(false);
  };

  handleIncrementSuspensions = async (incrementer) => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const res = await this.props.incrementPlayerSuspension(
      this.state.baseState._id,
      incrementer,
      { callback: this.indicateProgress, bar: 0 }
    );
    if (res.status === 200) {
      toast.success(res.data);
      this.toggleModal("incrementSuspensionsOpen");
      return this.loadData();
    } else toast.error(res.data);
    this.context.setLoading(false);
  };

  toggleModal = (id) => {
    this.setState({ [id]: this.state[id] ? false : true });
  };

  render() {
    const {
      source,
      teams,
      division,
      captain,
      captainID,
      baseState,
      emailOpen,
      emailSent,
      incrementSuspensionsOpen,
    } = this.state;

    const disabled = source === "info" ? "disabled" : "";
    const user = this.props.getCurrentUser();

    return (
      <div className="centered-small-input-area">
        {division &&
        division.rostersLocked &&
        user.role.includes("captain") &&
        source === "edit" ? (
          <WarningHeader>
            Rosters are locked for this division. You will only be able to edit
            the player's{" "}
            {user.sport.toLowerCase().includes("diamond")
              ? "roster position"
              : "shirt number"}
          </WarningHeader>
        ) : null}
        <div className="row">
          {(captainID === user._id || user.role.includes("admin")) &&
          !baseState.userID &&
          this.props.playerID !== "new" ? (
            <div className="col">
              <button
                className={
                  "btn btn-sm btn-" + (this.state.copiedLink ? "info" : "light")
                }
                onClick={() => this.setState({ emailOpen: true })}
              >
                {emailSent || baseState.email
                  ? `Pending response from ${
                      emailSent || baseState.email
                    }. Click to send a reminder or to a different address.`
                  : "Click here to email the registration link to this player."}
              </button>
            </div>
          ) : null}
          <div className="col text-right">
            {(!baseState.profileID?.hasAvatar ||
              baseState.profileID?.hasAvatar.includes("blocked")) &&
            ((this.props.org?.misc?.userImages === "Yes" &&
              user.role.includes("admin")) ||
              captainID === user._id) ? (
              <div>
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() =>
                    this.setState({
                      imageEditOpen: !this.state.imageEditOpen,
                    })
                  }
                >
                  Upload Image for Player
                </button>
                {this.state.imageEditOpen ? (
                  <div>
                    <p>
                      Upload a profile image for this player. If the player
                      uploads their own image it will override this one.
                    </p>
                    <Avatar
                      uploadFunction={(image) =>
                        this.props.manageImage("player", baseState._id, image)
                      }
                      height={150}
                      width={150}
                      saveTitle="Save Player Image"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <ImageHeader
          isNew={this.props.playerID === "new"}
          team={baseState.teamID}
          player={baseState}
          org={this.props.org}
        />
        {teams.length === 0 ? (
          <div>
            <WarningHeader>
              You must create at least one team before adding a player
            </WarningHeader>
            <CLink
              path="/teams?q=new"
              button={true}
              buttonStyle="btn-md btn-block btn-info"
            >
              Add New Team
            </CLink>
          </div>
        ) : (
          <div>
            <div>
              {disabled ? (
                <InfoComponent
                  userRole={user.role}
                  data={this.state.baseState}
                  fields={this.getInfoFields()}
                  handleSwitchToEditMode={
                    user.role.includes("admin") || captainID === user._id
                      ? this.handleSwitchToEditMode
                      : null
                  }
                />
              ) : (
                this.renderWholeForm(
                  "Player",
                  "/players",
                  {
                    listHeader: "Required Fields",
                    listItems: [
                      "Player First Name",
                      "Player Last Name",
                      "Team Name",
                    ],
                    text: `${
                      user.role.includes("admin")
                        ? `A player must be tied to a team at all times.
                      Move the player to the Free Agents team if you aren't sure of their final team.`
                        : ""
                    }
                ${
                  this.props.location.search.includes("new")
                    ? "If you include an email address then a registration link will be sent out."
                    : ""
                }
                ${
                  user.role.includes("admin")
                    ? "Any player who has a user registered to them can be made team captain."
                    : ""
                }`,
                  },
                  <div>
                    {this.renderInput(
                      "firstName",
                      "* Player First Name",
                      "autofocus",
                      "text",
                      disabled
                    )}
                    {this.renderInput(
                      "lastName",
                      "* Player Last Name",
                      "",
                      "text",
                      disabled
                    )}
                    {this.renderSelect(
                      "teamName",
                      "* Team Name",
                      teams,
                      captain || disabled,
                      {
                        header: "Team Name",
                        text: `A Player must be tied to a team at all times unless they are a free agent.
                      Team captains cannot be moved between teams.`,
                        direction: "right",
                        className: "icon-mobile",
                      },
                      null,
                      user.role.includes("admin")
                        ? {
                            onClick: () =>
                              navigateTo(
                                "/teams?q=new",
                                this.props.history,
                                this.context
                              ),
                            tooltipText: "Add New Team",
                            icon: "add",
                            class: "btn-info",
                          }
                        : null
                    )}
                    {this.renderInput(
                      "shirtNumber",
                      this.props.org?.sport.toLowerCase().includes("diamond")
                        ? "Roster Position"
                        : "Shirt Number",
                      "",
                      "number",
                      disabled,
                      "999",
                      "-1",
                      {
                        header: this.props.org?.sport
                          .toLowerCase()
                          .includes("diamond")
                          ? "Roster Position"
                          : "Shirt Number",
                        text: `The player's number will be displayed on the refereeing screen to help the referee select the correct player for an event during the match.
                    The team captain can update the numbers at any time before the match is started to by the referee.
                    Valid numbers are between -1 and 999. Enter -1 as a player's number if you do not want them to show on the scorecard during a match.
                    Players are also able to add a profile image that will display during the match, it is suggested that at least one of these identification methods is used.`,
                        direction: "right",
                        className: "icon-mobile",
                      }
                    )}
                    {this.props.playerID === "new" &&
                      this.renderInput(
                        "email",
                        "Email Address",
                        "",
                        "",
                        "",
                        "",
                        "",
                        {
                          header: "Email Address",
                          text: `If you enter an email address here a registration email will go out to the player to create their user account.
                      If you do not know the player's email at this time you can leave this field blank and send the email at a later time from either the team info or player info page.`,
                          direction: "up",
                          className: "icon-mobile",
                        }
                      )}
                  </div>
                )
              )}
            </div>
            <br />
            {user.role.includes("admin") && baseState._id && (
              <div>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggleModal("incrementSuspensionsOpen");
                  }}
                >
                  Update Player Suspension
                </button>
                <CustomAlert
                  dialog={"Manually update player suspension"}
                  isOpen={incrementSuspensionsOpen}
                  close={this.toggleModal}
                  id={"incrementSuspensionsOpen"}
                >
                  <div className="row">
                    <div className="col">
                      <button
                        className="btn btn-block btn-sm btn-info"
                        onClick={() => this.handleIncrementSuspensions(-1)}
                      >
                        Decrement
                      </button>
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-block btn-sm btn-info"
                        onClick={() => this.handleIncrementSuspensions(1)}
                      >
                        Increment
                      </button>
                    </div>
                  </div>
                </CustomAlert>
              </div>
            )}
            <CustomPrompt
              dialog="Enter the player's email"
              callback={this.sendRegistrationEmail}
              isOpen={emailOpen}
              close={this.toggleModal}
              id="emailOpen"
            />
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
      </div>
    );
  }
}

export default PlayerForm;

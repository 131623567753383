import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import IconRender from "../icons/iconRender";
import HeaderContext from "../../../context/headerContext";
import { navigateTo } from "../customs/customLinks";
import TooltipButton from "../tooltip/tooltipButton";
import { getCurrentUser } from "../../../services/userService";
import { isAdmin } from "../../../utils/permissionsUtil";
import allowables from "../../../utils/allowables";

const CardList = ({
  items,
  nameOverrideFunc,
  fields = [],
  unshiftFields = [],
  topItems = [],
  bottomItems = [],
  editPath,
  onDelete,
  onPurge,
  extraRightSideItems = [],
  overrideColClass = "col-2",
  noLinks,
}) => {
  const header = useContext(HeaderContext);
  const history = useHistory();
  const user = getCurrentUser();

  const mapContent = (fields, item) => {
    if (!fields.length) return null;
    return fields.map((field, idx) => (
      <div key={idx} className="card-padding">
        {field.content(item)}
      </div>
    ));
  };

  return (
    <div className="cardview">
      {items.map((item, cardIndex) => (
        <div
          className="cardview-card"
          key={cardIndex}
          id={allowables.splitName(item.name).replaceAll(" ", "_")}
        >
          {nameOverrideFunc ? (
            nameOverrideFunc(item)
          ) : (
            <div
              className={`cardview-header ${noLinks ? "" : "clickable"}`}
              onClick={
                noLinks
                  ? null
                  : () =>
                      navigateTo(
                        `${editPath}?info&q=${item._id}`,
                        history,
                        header
                      )
              }
            >
              {allowables.splitName(item.name)}
            </div>
          )}
          {mapContent(topItems, item)}
          <div className="row">
            {unshiftFields.length > 0 && (
              <div className="col-2 align-self-center">
                {mapContent(unshiftFields, item)}
              </div>
            )}

            <div className="col">
              <div className="card-padding">
                {fields.map((field, idx) => {
                  const value = field.text?.(item);
                  return field.neverHide || value ? (
                    <div key={idx} className="card-text-padding">
                      <span className="cardview-text-small">
                        {field.header}:
                      </span>
                      &nbsp;&nbsp;
                      <span className="cardview-text">{value}</span>
                    </div>
                  ) : null;
                })}
              </div>
            </div>

            {(extraRightSideItems.length > 0 ||
              (isAdmin(user) && !noLinks)) && (
              <div className={`${overrideColClass} card-padding`}>
                {editPath ? (
                  <TooltipButton
                    className="btn btn-sm btn-secondary m-1"
                    onClick={() =>
                      navigateTo(`${editPath}?q=${item._id}`, history, header)
                    }
                    tooltipText={`Edit ${allowables.splitName(item.name)}`}
                    tooltipDirection="left"
                  >
                    <IconRender name="edit" />
                  </TooltipButton>
                ) : null}
                {onPurge ? (
                  <TooltipButton
                    className="btn btn-sm btn-light m-1"
                    onClick={() => onPurge(item)}
                    tooltipText={`Purge ${allowables.splitName(item.name)}`}
                    tooltipDirection="left"
                  >
                    <IconRender name="purge" />
                  </TooltipButton>
                ) : null}
                {onDelete ? (
                  <TooltipButton
                    className="btn btn-sm btn-dark m-1"
                    onClick={() => onDelete(item)}
                    tooltipText={`Delete ${allowables.splitName(item.name)}`}
                    tooltipDirection="left"
                  >
                    <IconRender name="delete" />
                  </TooltipButton>
                ) : null}
                {extraRightSideItems.map((rsi, idx) => (
                  <div key={idx}>{rsi.displayFunc(item)}</div>
                ))}
              </div>
            )}
          </div>
          {mapContent(bottomItems, item)}
        </div>
      ))}
    </div>
  );
};

export default CardList;

import React, { useState, useEffect } from "react";

import MiniHeader from "../pageComponents/miniHeader";
import Checkbox from "./checkBox";
import allowables from "../../../utils/allowables";

const MultiFieldInput = ({
  title,
  values,
  properties,
  dataProperty,
  onChange,
  hasOwnHeader,
  sectionName,
  maxFields,
}) => {
  const [sections, setSections] = useState(
    new Array(values.length || 1).fill(null)
  );

  useEffect(() => {
    setSections(new Array(values.length || 1).fill(null));
  }, [values.length]);

  const removeLastSection = (e) => {
    e.preventDefault();
    onChange(null, dataProperty);
    setSections(new Array(sections.length - 1).fill(null));
  };

  const clearAll = (e) => {
    e.preventDefault();
    onChange(null, dataProperty, true);
    setSections(new Array(1).fill(null));
  };

  return (
    <React.Fragment>
      {!hasOwnHeader && <MiniHeader>{title}</MiniHeader>}
      <div className="form-divided-section">
        {sections.map((f, i) => (
          <div key={i}>
            <i>
              {sectionName || "Section"} {i + 1}
            </i>
            {properties.map((p) => (
              <div key={p.name}>
                {!p.label ? (
                  <label
                    htmlFor={p.name + "," + i + "," + dataProperty}
                    style={p.style}
                  >
                    {allowables.capLetterOne(p.name)}
                  </label>
                ) : null}
                {p.type === "input" ? (
                  <input
                    id={p.name + "," + i + "," + dataProperty}
                    className="form-control custom-form"
                    value={values[i] ? values[i][p.name] : ""}
                    onChange={onChange}
                  />
                ) : p.type === "textarea" ? (
                  <textarea
                    id={p.name + "," + i + "," + dataProperty}
                    className="form-control custom-form"
                    value={values[i] ? values[i][p.name] : ""}
                    onChange={onChange}
                    rows="4"
                  />
                ) : p.type === "checkbox" ? (
                  <Checkbox
                    onChange={(value) =>
                      onChange({
                        target: {
                          value: !values[i]?.[p.name],
                          id: p.name + "," + i + "," + dataProperty,
                        },
                      })
                    }
                    id={p.name + "," + i + "," + dataProperty}
                    value={values[i] ? values[i][p.name] : false}
                    label={p.label || allowables.capLetterOne(p.name)}
                  />
                ) : null}
              </div>
            ))}
            <hr className="custom" />
          </div>
        ))}

        <div className="row">
          <div className="col text-center">
            <button
              className="btn btn-sm btn-dark"
              onClick={(e) => clearAll(e)}
            >
              Clear All Sections
            </button>
          </div>
          <div className="col text-center">
            {sections.length > 1 && (
              <button
                className="btn btn-sm btn-secondary"
                onClick={(e) => removeLastSection(e)}
              >
                Remove Last Section
              </button>
            )}
          </div>
          <div className="col text-center">
            {(!maxFields || sections.length < maxFields) && (
              <button
                className="btn btn-sm btn-info"
                onClick={(e) => {
                  e.preventDefault();
                  if (!maxFields || sections.length < maxFields)
                    setSections(new Array(sections.length + 1).fill(null));
                }}
              >
                Add Section
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MultiFieldInput;

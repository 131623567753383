import React, { useEffect, useState } from "react";
import MvpVoting from "./mvpVoting";
import { getCurrentUser } from "../../services/userService";
import MvpAdmin from "./mvpAdmin";
import WarningHeader from "../common/pageComponents/warningHeader";
import NotFound from "../notFound";

const MvpVotingRedirect = ({ org }) => {
  const [allowedVotes, setAllowedVotes] = useState({
    ownTeam: false,
    otherTeam: false,
  });

  const user = getCurrentUser();
  const mvpVoting = org?.misc?.mvpVoting;

  useEffect(() => {
    const votes = { ...allowedVotes };
    mvpVoting.categories.forEach((cat) => {
      if (cat.canVoteForOwnTeam) votes.ownTeam = true;
      if (cat.canVoteForOtherTeams) votes.otherTeam = true;
    });
    setAllowedVotes(votes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!mvpVoting || !mvpVoting.categories?.length)
    return (
      <WarningHeader>Your League does not allow voting for MVP</WarningHeader>
    );

  return user?.role?.includes("player") ? (
    <MvpVoting mvpVoting={mvpVoting} allowedVotes={allowedVotes} />
  ) : user?.role?.includes("admin") ? (
    <MvpAdmin mvpVoting={mvpVoting} allowedVotes={allowedVotes} />
  ) : (
    <NotFound accessDenied={true} />
  );
};

export default MvpVotingRedirect;

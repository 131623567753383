import React from "react";
import CardList from "../common/cardView/cardList";
import allowables from "../../utils/allowables";
import BoolVis from "../common/icons/booleanRep";

const SeaonsCardView = ({ seasons, onDelete }) => {
  return (
    <CardList
      items={seasons}
      fields={[
        {
          header: "Registration End Date",
          text: (season) => allowables.shortDate(season.registrationEndDate),
        },
        {
          header: "Start Date",
          text: (season) => allowables.shortDate(season.startDate),
        },
        {
          header: "End Date",
          text: (season) => allowables.shortDate(season.endDate),
        },
        {
          header: "Location",
          text: (season) => season.location,
        },
        {
          header: "Active",
          text: (season) => <BoolVis bool={season.active} />,
        },
      ]}
      onDelete={onDelete}
      editPath={"/seasons"}
    />
  );
};

export default SeaonsCardView;

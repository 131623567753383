import React from "react";

import PlayoffBracketCanvas from "../brackets/playoffBracketCanvas";
import MiniHeader from "../common/pageComponents/miniHeader";

const SpectateBrackets = ({ matches, onSelectMatch, onHeaderClick }) => {
  return (
    <div>
      <MiniHeader onClick={onHeaderClick}>Brackets</MiniHeader>
      <div className="form-divided-section" style={{ padding: 5 }}>
        {matches.length === 0 ? (
          <p className="text-center cardview-card">
            There are no playoff matches for this division
          </p>
        ) : (
          <PlayoffBracketCanvas
            matches={matches}
            spectate={true}
            onSelectMatch={onSelectMatch}
            hideFormatter={true}
            containerStyle={{
              maxWidth: 650,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SpectateBrackets;

import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";

import toast from "../../utils/toast";
import allowables from "../../utils/allowables";
import { CBack } from "../common/customs/customLinks";
import MiniHeader from "../common/pageComponents/miniHeader";
import SideBySideView from "../common/pageComponents/sideBySideView";
import HeaderContext from "../../context/headerContext";
import { getCurrentUser, refreshUser } from "../../services/userService";
import { saveOrg, updateOrg } from "../../services/orgService";
import { getCurrencies } from "../../services/paymentService";
import TooltipButton from "../common/tooltip/tooltipButton";
import NewFeatureBadge from "../common/pageComponents/newFeatureBadge";

class OrgForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      name: "",
      sport: "",
      redCard: 2,
      twoYellowsInGame: 1,
      yellowsInTournament: 3,
      accumulateYellows: false,
      captainApproval: false,
      spectatingAllowed: "",
      codeOfConduct: [],
      requireVaccine: false,
      whoPays: "Team",
      currency: "usd",
      amount: 0,
      commentHint: "",
      userImages: false,
      longitude: "",
      latitude: "",
      waiverWireLength: 1,
      regionName: "",
      useRegion: false,
      rankingType: "Stats",
      eloKFactor: 32,
      defaultApproval: "Neither",
      customFlag1: "",
      customFlag2: "",
      externalLinks: [{ url: "", text: "" }],
      vaccinationEmail: "",
      waiverWireOn: false,
      profanityFilter: true,
      requirePaymentBeforeSignup: false,
      customerServiceEmail: "",
      customerServicePhoneNumber: "",
      leagueWebsiteAddress: "",
      freeAgentLinkActive: true,
      teamCaptainLinkActive: true,
      liveScorecards: false,
      outsPerInning: 3,
      inningsPerGame: 9,
      hideFoulBalls: false,
      trackAssists: false,
      refundPolicy: "",
      userReportedStats: false,
      pointsPerWin: 3,
      pointsPerTie: 1,
      pointsPerLoss: 0,
      pointsPerCleanSheet: 0,
      maxBonusPerGoal: 0,
      shirtSizeRequired: false,
      genderIdentityRequired: false,
      informationRequestReason: "",
      pronounsRequired: false,
      allowMatchSelfAssignment: false,
      categories: [
        {
          name: "",
          singleVote: false,
          canVoteOutsideDivision: false,
          canVoteForOtherTeams: false,
          canVoteForOwnTeam: false,
        },
      ],
    },
    baseState: {
      name: "",
      sport: "",
      redCard: "",
      twoYellowsInGame: "",
      yellowsInTournament: "",
      accumulateYellows: false,
      captainApproval: false,
      spectatingAllowed: "",
      codeOfConduct: [],
      requireVaccine: false,
      whoPays: "Team",
      amount: 0,
      currency: "usd",
      commentHint: "",
      userImages: false,
      longitude: "",
      latitude: "",
      waiverWireLength: 1,
      regionName: "",
      useRegion: false,
      rankingType: "Stats",
      eloKFactor: 32,
      defaultApproval: "Neither",
      customFlag1: "",
      customFlag2: "",
      externalLinks: [{ url: "", text: "" }],
      vaccinationEmail: "",
      waiverWireOn: false,
      profanityFilter: true,
      requirePaymentBeforeSignup: false,
      customerServiceEmail: "",
      customerServicePhoneNumber: "",
      leagueWebsiteAddress: "",
      freeAgentLinkActive: true,
      teamCaptainLinkActive: true,
      liveScorecards: false,
      outsPerInning: 3,
      inningsPerGame: 9,
      hideFoulBalls: false,
      trackAssists: false,
      refundPolicy: "",
      userReportedStats: false,
      pointsPerWin: 3,
      pointsPerTie: 1,
      pointsPerLoss: 0,
      pointsPerCleanSheet: 0,
      maxBonusPerGoal: 0,
      shirtSizeRequired: false,
      genderIdentityRequired: false,
      informationRequestReason: "",
      pronounsRequired: false,
      allowMatchSelfAssignment: false,
      categories: [
        {
          name: "",
          singleVote: false,
          canVoteOutsideDivision: false,
          canVoteForOtherTeams: false,
          canVoteForOwnTeam: false,
        },
      ],
    },
    errors: {},
    currencies: [],
    codeOfConductModalOpen: false,
    apiError: null,
    minWidth: 1000,
    // start with the top section open
    paymentOpen: true,
    playerRegistrationOptionsOpen: false,
    userInteractionsOpen: false,
    contactOptionsOpen: false,
    adminOptionsOpen: false,
    mvpOptionsOpen: false,
    externalLinksOpen: false,
    soccerOptionsOpen: false,
    diamondSportOptionsOpen: false,
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    if (!this.props.source) {
      const currenciesRes = await getCurrencies();
      if (currenciesRes.status === 200) {
        this.setState({ currencies: currenciesRes.data });
      }
    }

    if (this.props.org)
      this.setState({
        data: this.mapToViewModel(this.props.org),
        baseState: this.props.org,
      });
    this.context.setLoading(false);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.org !== this.props.org) await this.componentDidMount();
  }

  schema = {
    name: Joi.string().required().label("League Name").min(3).max(50),
    sport: Joi.string().required().label("Sport"),
    redCard: Joi.number()
      .integer()
      .min(0)
      .max(9)
      .label("Number of matches suspended for receiving a straight red card."),
    twoYellowsInGame: Joi.number()
      .integer()
      .min(0)
      .max(9)
      .label(
        "Number of matches suspended for receiving two yellow cards in one match."
      ),
    yellowsInTournament: Joi.number()
      .integer()
      .min(0)
      .max(25)
      .label(
        "Number of yellow cards accumulated throughout division/tournament for single game suspension."
      ),
    accumulateYellows: Joi.boolean().label("Accumulate Two Yellows?"),
    captainApproval: Joi.boolean()
      .optional()
      .label("Require Captains Approval"),
    spectatingAllowed: Joi.string()
      .required()
      .min(3)
      .max(55)
      .allow("")
      .label("Website Extension"),
    codeOfConduct: Joi.array()
      .items(Joi.object())
      .optional()
      .allow(null)
      .label("Code of Conduct"),
    requireVaccine: Joi.boolean()
      .optional()
      .label("Require Vaccination Information"),
    whoPays: Joi.string()
      .required()
      .allow("Team", "Player")
      .label("Fees Taken From"),
    amount: Joi.number().required().min(0).label("Amount"),
    currency: Joi.string().required().label("Currency"),
    commentHint: Joi.string()
      .optional()
      .min(10)
      .max(500)
      .allow("")
      .label("Comment Hint"),
    userImages: Joi.boolean().optional().label("User Images"),
    waiverWireLength: Joi.number()
      .integer()
      .optional()
      .label("Waiver Wire Length")
      .min(1)
      .max(7)
      .allow(""),
    useRegion: Joi.boolean().optional().label("Show Local Map"),
    longitude: Joi.number().optional().label("Longitude").allow(""),
    latitude: Joi.number().optional().label("Latitude").allow(""),
    regionName: Joi.string().optional().label("Region Name").allow(""),
    rankingType: Joi.string()
      .optional()
      .valid("Wins", "Stats", "Elo")
      .label("Ranking Type"),
    eloKFactor: Joi.number()
      .integer()
      .min(10)
      .max(50)
      .allow(null)
      .label("Elo K Factor"),
    defaultApproval: Joi.string()
      .optional()
      .valid(
        "Neither",
        "Remove Comments",
        "Fully Approve",
        "Remove Comments and Fully Approve"
      )
      .label("Default Approval"),
    customFlag1: Joi.string().optional().allow("").label("Custom Flag 1"),
    customFlag2: Joi.string().optional().allow("").label("Custom Flag 2"),
    externalLinks: Joi.array().optional().label("External Links"),
    requirePaymentBeforeSignup: Joi.boolean()
      .optional()
      .label("Payment Required before Signup"),
    vaccinationEmail: Joi.string()
      .optional()
      .allow("")
      .label("Vaccination Email"),
    customerServiceEmail: Joi.string()
      .optional()
      .allow("")
      .label("Customer Service Email"),
    customerServicePhoneNumber: Joi.string()
      .optional()
      .allow("")
      .label("Customer Service Phone Number"),
    leagueWebsiteAddress: Joi.string()
      .uri()
      .optional()
      .allow("")
      .label("League Website"),
    waiverWireOn: Joi.boolean().optional(),
    profanityFilter: Joi.boolean().optional(),
    freeAgentLinkActive: Joi.boolean().optional(),
    teamCaptainLinkActive: Joi.boolean().optional(),
    liveScorecards: Joi.boolean().optional(),
    outsPerInning: Joi.number().integer().optional().default(3),
    inningsPerGame: Joi.number().integer().optional().default(9),
    hideFoulBalls: Joi.boolean().optional(),
    trackAssists: Joi.boolean().optional(),
    refundPolicy: Joi.string()
      .optional()
      .max(500)
      .allow("")
      .label("Payment Refund Policy"),
    userReportedStats: Joi.boolean().optional(),
    pointsPerWin: Joi.number().required().min(0).label("Points per Win"),
    pointsPerTie: Joi.number().required().min(0).label("Points per Tie"),
    pointsPerLoss: Joi.number().required().min(0).label("Points per Loss"),
    pointsPerCleanSheet: Joi.number()
      .required()
      .min(0)
      .label("Points per Clean Sheet"),
    maxBonusPerGoal: Joi.number()
      .required()
      .min(0)
      .label("Max Bonus Points per Goal per Match"),
    shirtSizeRequired: Joi.boolean().optional(),
    genderIdentityRequired: Joi.boolean().optional(),
    pronounsRequired: Joi.boolean().optional(),
    informationRequestReason: Joi.string().optional().allow("").max(200),
    allowMatchSelfAssignment: Joi.boolean().optional(),
    categories: Joi.array().optional(),
  };

  calculateAmountWithFees = () => {
    const amount = Number(this.state.data.amount);
    const amountWithFees =
      Math.round((((isNaN(amount) ? 0 : amount) + 2.3) / (1 - 0.034)) * 100) /
      100;
    this.setState({ data: { ...this.state.data, amount: amountWithFees } });
  };

  doSubmit = async () => {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    const user = getCurrentUser();
    let data = { ...this.state.data };

    // add in diamond sport options
    const diamondSportOptions = {
      outsPerInning: Number(data.outsPerInning),
      inningsPerGame: Number(data.inningsPerGame),
      hideFoulBalls: data.hideFoulBalls,
    };
    const soccerSportOptions = {
      trackAssists: data.trackAssists,
      pointsPerCleanSheet: Number(data.pointsPerCleanSheet),
      maxBonusPerGoal: Number(data.maxBonusPerGoal),
    };
    diamondSportOptions.sportName = this.props.org
      ? this.props.org.misc?.diamondSportOptions?.sportName
      : ["Baseball", "Softball", "Kickball"].includes(data.sport)
      ? data.sport
      : "";

    const simpleScoringOptions = {
      scorecardType: this.props.org
        ? this.props.org.misc?.simpleScoringOptions?.scorecardType || ""
        : ["Tennis"].includes(data.sport)
        ? data.sport
        : "",
      pointsPerWin: Number(data.pointsPerWin),
      pointsPerTie: Number(data.pointsPerTie),
      pointsPerLoss: Number(data.pointsPerLoss),
    };

    const requiredPersonalInformation = {
      shirtSizeRequired: data.shirtSizeRequired,
      genderIdentityRequired: data.genderIdentityRequired,
      pronounsRequired: data.pronounsRequired,
      informationRequestReason: data.informationRequestReason,
    };

    let voteCategories = !data.categories[0]?.name ? [] : data.categories;
    let mvpError;
    let mappedCategories = [];
    voteCategories.forEach((cat) => {
      if (!cat.canVoteForOtherTeams && !cat.canVoteForOwnTeam)
        mvpError = `MVP Category "${cat.name}" must be enabled for other team and/or own team`;
      mappedCategories.push({
        ...cat,
        singleVote: cat.singleVote || false,
        canVoteForOtherTeams: cat.canVoteForOtherTeams || false,
        canVoteOutsideDivision: cat.canVoteOutsideDivision || false,
        canVoteForOwnTeam: cat.canVoteForOwnTeam || false,
        isLocked: cat.isLocked || false,
      });
    });
    if (mvpError) {
      this.context.setLoading(false);
      this.setState({ mvpOptionsOpen: true });
      return toast.error(mvpError);
    }
    const mvpVoting = {
      categories: mappedCategories,
    };

    // sport mapping
    if (["baseball", "kickball", "softball"].includes(data.sport.toLowerCase()))
      data.sport = "Diamond Sports";
    else if (["tennis"].includes(data.sport.toLowerCase()))
      data.sport = "Simple Scoring";
    else if (data.sport === "Generic") data.sport = "Simple Scoring";

    // stripe data
    data.stripe = {
      whoPays: data.whoPays,
      amount: Number(data.amount),
      currency: data.currency,
    };
    delete data.whoPays;
    delete data.amount;
    delete data.currency;

    // transform checkbox data until we can switch to bools in DB
    data.requireVaccine = data.requireVaccine ? "Yes" : "No";
    data.captainApproval = data.captainApproval ? "Yes" : "No";

    // add in misc data
    let region;
    if (data.useRegion) {
      const longitude = Number(data.longitude);
      const latitude = Number(data.latitude);
      if (
        !longitude ||
        !latitude ||
        isNaN(longitude) ||
        isNaN(latitude) ||
        latitude > 90 ||
        latitude < -90 ||
        longitude > 180 ||
        longitude < -180
      ) {
        this.context.setLoading(false);
        return toast.error(
          "If using local map both longitude and latitude must be valid"
        );
      }
      region = {
        longitude: Number(longitude.toFixed(4)),
        latitude: Number(latitude.toFixed(4)),
        name: data.regionName,
      };
    } else region = null;

    let externalLinks = data.externalLinks;
    if (!data.externalLinks[0]?.url && !data.externalLinks[0]?.text)
      externalLinks = [];

    data.misc = {
      commentHint: data.commentHint,
      userImages: data.userImages ? "Yes" : "No",
      region,
      rankingType: data.rankingType,
      eloKFactor: data.eloKFactor,
      waiverWireLength: data.waiverWireLength || 1,
      defaultApproval:
        data.defaultApproval === "Remove Comments and Fully Approve"
          ? "Both"
          : data.defaultApproval,
      customFlag1: data.customFlag1,
      customFlag2: data.customFlag2,
      externalLinks: externalLinks,
      requirePaymentBeforeSignup: data.requirePaymentBeforeSignup
        ? "Yes"
        : "No",
      vaccinationEmail: data.vaccinationEmail,
      waiverWireOn: data.waiverWireOn,
      profanityFilter: data.profanityFilter,
      customerServiceEmail: data.customerServiceEmail,
      customerServicePhoneNumber: data.customerServicePhoneNumber,
      leagueWebsiteAddress: data.leagueWebsiteAddress,
      activeRegistrationLinks: {
        freeAgent: data.freeAgentLinkActive,
        teamCaptain: data.teamCaptainLinkActive,
      },
      liveScorecards: data.liveScorecards,
      diamondSportOptions,
      soccerSportOptions,
      simpleScoringOptions,
      refundPolicy: data.refundPolicy,
      userReportedStats: data.userReportedStats,
      requiredPersonalInformation,
      allowMatchSelfAssignment: data.allowMatchSelfAssignment,
      mvpVoting,
    };

    delete data.useRegion;
    delete data.longitude;
    delete data.latitude;
    delete data.regionName;
    delete data.commentHint;
    delete data.waiverWireLength;
    delete data.userImages;
    delete data.rankingType;
    delete data.eloKFactor;
    delete data.defaultApproval;
    delete data.customFlag1;
    delete data.customFlag2;
    delete data.externalLinks;
    delete data.requirePaymentBeforeSignup;
    delete data.vaccinationEmail;
    delete data.waiverWireOn;
    delete data.profanityFilter;
    delete data.customerServiceEmail;
    delete data.customerServicePhoneNumber;
    delete data.leagueWebsiteAddress;
    delete data.freeAgentLinkActive;
    delete data.teamCaptainLinkActive;
    delete data.liveScorecards;
    delete data.outsPerInning;
    delete data.inningsPerGame;
    delete data.hideFoulBalls;
    delete data.trackAssists;
    delete data.refundPolicy;
    delete data.userReportedStats;
    delete data.pointsPerWin;
    delete data.pointsPerTie;
    delete data.pointsPerLoss;
    delete data.pointsPerCleanSheet;
    delete data.maxBonusPerGoal;
    delete data.shirtSizeRequired;
    delete data.genderIdentityRequired;
    delete data.pronounsRequired;
    delete data.informationRequestReason;
    delete data.allowMatchSelfAssignment;
    delete data.categories;

    data.accumulateYellows = data.accumulateYellows ? "Yes" : "No";
    if (user.orgID) {
      const response = await updateOrg(data);
      if (response.status === 200) {
        toast.success("Updated successfully.");
        return (window.location = "/league");
      } else {
        toast.error(response.data);
      }
    } else {
      const orgID = "new";
      const org = data;
      const response = await saveOrg(orgID, org);
      if (response.status === 200) {
        await refreshUser();
        toast.success("League created.");
        return (window.location = "/gettingstarted");
      } else toast.error(response.data);
    }
    this.context.setLoading(false);
  };

  mapToViewModel(org) {
    return {
      name: org.name || "",
      sport: org.sport || "",
      redCard: org.redCard || 0,
      twoYellowsInGame: org.twoYellowsInGame || 0,
      yellowsInTournament: org.yellowsInTournament || 0,
      accumulateYellows: org.accumulateYellows === "Yes",
      captainApproval: org.captainApproval === "Yes",
      spectatingAllowed: org.spectatingAllowed || "",
      codeOfConduct: org.codeOfConduct || [],
      requireVaccine: org.requireVaccine === "Yes",
      waiverWireLength: (org.misc && org.misc.waiverWireLength) || 1,
      whoPays: (org.stripe && org.stripe.whoPays) || "",
      amount: (org.stripe && org.stripe.amount) || 0,
      currency: (org.stripe && org.stripe.currency) || "",
      commentHint: (org.misc && org.misc.commentHint) || "",
      userImages: org.misc && org.misc.userImages === "Yes",
      useRegion: org.misc && org.misc.region ? true : false,
      longitude:
        (org.misc &&
          org.misc.region &&
          org.misc.region.longitude &&
          org.misc.region.longitude.toFixed(4)) ||
        0,
      latitude:
        (org.misc &&
          org.misc.region &&
          org.misc.region.latitude &&
          org.misc.region.latitude.toFixed(4)) ||
        0,
      regionName: (org.misc && org.misc.region && org.misc.region.name) || "",
      rankingType: (org.misc && org.misc.rankingType) || "Stats",
      eloKFactor: (org.misc && org.misc.eloKFactor) || 32,
      defaultApproval:
        (org.misc &&
        org.misc.defaultApproval &&
        org.misc.defaultApproval === "Both"
          ? "Remove Comments and Fully Approve"
          : org.misc?.defaultApproval) || "Neither",
      customFlag1: (org.misc && org.misc.customFlag1) || "",
      customFlag2: (org.misc && org.misc.customFlag2) || "",
      externalLinks:
        org.misc && org.misc.externalLinks.length > 0
          ? org.misc.externalLinks
          : [{ url: "", text: "" }],
      requirePaymentBeforeSignup:
        org.misc && org.misc.requirePaymentBeforeSignup === "Yes",
      vaccinationEmail: org.misc?.vaccinationEmail || "",
      customerServiceEmail: org.misc?.customerServiceEmail || "",
      customerServicePhoneNumber: org.misc?.customerServicePhoneNumber || "",
      leagueWebsiteAddress: org.misc?.leagueWebsiteAddress || "",
      waiverWireOn: (org.misc && org.misc.waiverWireOn) || false,
      profanityFilter:
        typeof (org.misc && org.misc.profanityFilter) === "boolean"
          ? org.misc.profanityFilter
          : true,
      freeAgentLinkActive: org.misc?.activeRegistrationLinks
        ? org.misc?.activeRegistrationLinks.freeAgent || false
        : true,
      teamCaptainLinkActive: org.misc?.activeRegistrationLinks
        ? org.misc?.activeRegistrationLinks.teamCaptain || false
        : true,
      liveScorecards: (org.misc && org.misc.liveScorecards) || false,
      outsPerInning: org.misc?.diamondSportOptions?.outsPerInning || 3,
      inningsPerGame: org.misc?.diamondSportOptions?.inningsPerGame || 9,
      hideFoulBalls:
        (org.misc &&
          org.misc.diamondSportOptions &&
          org.misc.diamondSportOptions.hideFoulBalls) ||
        false,
      trackAssists:
        (org.misc &&
          org.misc.soccerSportOptions &&
          org.misc.soccerSportOptions.trackAssists) ||
        false,
      refundPolicy: org?.misc?.refundPolicy || "",
      userReportedStats: (org.misc && org.misc.userReportedStats) || false,
      pointsPerWin: isNaN(org?.misc?.simpleScoringOptions?.pointsPerWin)
        ? 3
        : org?.misc?.simpleScoringOptions?.pointsPerWin,
      pointsPerTie: isNaN(org?.misc?.simpleScoringOptions?.pointsPerTie)
        ? 1
        : org?.misc?.simpleScoringOptions?.pointsPerTie,
      pointsPerLoss: isNaN(org?.misc?.simpleScoringOptions?.pointsPerLoss)
        ? 0
        : org?.misc?.simpleScoringOptions?.pointsPerLoss,
      pointsPerCleanSheet: isNaN(
        org?.misc?.soccerSportOptions?.pointsPerCleanSheet
      )
        ? 0
        : org?.misc?.soccerSportOptions?.pointsPerCleanSheet,
      maxBonusPerGoal: isNaN(org?.misc?.soccerSportOptions?.maxBonusPerGoal)
        ? 0
        : org?.misc?.soccerSportOptions?.maxBonusPerGoal,
      shirtSizeRequired:
        !!org?.misc?.requiredPersonalInformation?.shirtSizeRequired,
      genderIdentityRequired:
        !!org?.misc?.requiredPersonalInformation?.genderIdentityRequired,
      pronounsRequired:
        !!org?.misc?.requiredPersonalInformation?.pronounsRequired,
      informationRequestReason:
        org?.misc?.requiredPersonalInformation?.informationRequestReason || "",
      allowMatchSelfAssignment:
        (org.misc && org.misc.allowMatchSelfAssignment) || false,
      categories: org.misc?.mvpVoting?.categories || [],
    };
  }

  toggleModal = (e, id) => {
    if (e) e.preventDefault();
    this.setState({ [id]: this.state[id] ? false : true });
  };

  render() {
    const { minWidth, data } = this.state;
    const mappedSportName = this.state.data.sport
      .toLowerCase()
      .includes("diamond")
      ? this.state.baseState.misc?.diamondSportOptions?.sportName
      : this.state.data.sport.toLowerCase().includes("simple")
      ? this.state.baseState.misc?.simpleScoringOptions?.scorecardType
      : this.state.data.sport;

    const user = getCurrentUser();
    let nameInput = "";
    let sportInput = "";
    let currencyInput = "";
    if (!user.orgID) {
      nameInput = this.renderInput(
        "name",
        "* League Name",
        "autofocus",
        "string",
        "",
        "",
        "",
        {
          header: "League Name",
          text: "This field is required. It can only be changed in the future by contacting support.",
          direction: "right",
          className: "icon-mobile",
        }
      );
      sportInput = this.renderSelect(
        "sport",
        "* Sport",
        allowables.sports(true),
        "",
        {
          header: "Sport",
          text: `This field is required.
            Once set the sport cannot be changed.
            See the Available Sports tab on the homepage for info on what each sport offers.
            Even if your sport isn't listed here there may be a sport which offers the features you are looking for.`,
          direction: "right",
          className: "icon-mobile",
        }
      );
      currencyInput = this.renderSelectByValueOption(
        "currency",
        "Currency",
        this.state.currencies,
        "_id",
        "",
        {
          header: "Currency",
          text: `Select the currency in which the majority of your players will be making their payments.
          Selecting your correct currency can prevent extra charges when processing cards.
          If you don't see your currency please send an email to ${allowables.supportEmail} to request that it be added.`,
          direction: "right",
          className: "icon-mobile",
        }
      );
    }
    let refOrUmp = allowables.refOrUmp(this.props.org?.sport);

    return (
      <div className="centered-small-input-area">
        <div className="row">
          <div className="col">
            <h1>
              {!user.orgID ? "Create New League" : "Edit League Settings"}
            </h1>
          </div>
          <div className="col-4 text-right">
            <CBack>Go Back</CBack>
          </div>
        </div>

        {(this.props.source === "options" || !user.orgID) && (
          <form onSubmit={this.handleSubmit}>
            {!user.orgID && (
              <MiniHeader>
                League Name, Sport, and Currency are fixed when creating the
                League, all other settings can be changed later.
              </MiniHeader>
            )}
            <div className={!user.orgID ? "form-divided-section" : ""}>
              <SideBySideView
                doNotCenterOnStack={true}
                minWidth={minWidth}
                Components={[nameInput, sportInput, currencyInput]}
              />
            </div>

            {(this.props.source === "options" || !user.orgID) && (
              <React.Fragment>
                {user.orgID && (
                  <p className="text-center">
                    Changes made to settings are not saved until submitted
                  </p>
                )}
                <p className="text-center">Click Each Section to Expand</p>
                <MiniHeader
                  marginTop={20}
                  onClick={() =>
                    this.setState({
                      paymentOpen: !this.state.paymentOpen,
                    })
                  }
                  iconName={
                    this.state.paymentOpen ? "chevrondown" : "chevronright"
                  }
                  tabIndex="0"
                  id="paymentOptions"
                  scrollOnClick={!this.state.paymentOpen}
                >
                  Player Payment Information
                </MiniHeader>
                {this.state.paymentOpen && (
                  <div className="form-divided-section">
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderSelect(
                          "whoPays",
                          "Payment Collected From:",
                          [
                            { _id: "Team", name: "Team" },
                            { _id: "Player", name: "Player" },
                          ],
                          "",
                          {
                            header: "Who to Collect Payment From",
                            text: `You can select to either collect your payments from the team captain for an entire team or from individual players.`,
                            direction: "right",
                            className: "icon-mobile",
                          }
                        ),
                        <div className="row">
                          <div className="col">
                            {this.renderInput(
                              "amount",
                              "Amount",
                              "",
                              "number",
                              "",
                              "",
                              "0",
                              {
                                header: "Amount",
                                text: `How much to charge when a team captain or player pays their invoice.
              There are fees associated with collecting payments, please see our policies for more information.`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              "0.01"
                            )}
                          </div>
                          <div className="col-2 d-flex justify-content-center align-items-center">
                            <b>{this.state.data.currency.toUpperCase()}</b>
                          </div>
                        </div>,
                      ]}
                    />
                    <TooltipButton
                      buttonClass="btn btn-info btn-md"
                      onClick={this.calculateAmountWithFees}
                      tooltipDirection="right"
                      tooltipText={`Update payment amount to include processing fees, such that a payment collected will result in ${
                        this.state.data.amount
                      } ${this.state.data.currency?.toUpperCase()} deposited to your account`}
                    >
                      Include Fees
                    </TooltipButton>
                    {this.state.data.whoPays === "Player" &&
                      this.renderCheckbox(
                        "requirePaymentBeforeSignup",
                        "Payment Required before Signup",
                        "",
                        {
                          header: "Payment Required before Signup",
                          text: `If enabled every player signing up will be required to complete payment of their dues before they join your league.
                      This can be useful to save yourself time chasing down players who are late to make their payments.
                      This is currently only available for leagues who collect payment from individual players.`,
                          direction: "right",
                          className: "icon-mobile",
                        }
                      )}
                    {this.renderTextArea(
                      "refundPolicy",
                      "Payment Refund Policy",
                      "",
                      "3",
                      {
                        header: "Payment Refund Policy",
                        text: `Enter your refund policy for payments received from your players.
                        This will display to the players before they make their payment.`,
                        direction: "right",
                        className: "icon-mobile",
                      },
                      false,
                      "Enter your refund policy"
                    )}
                  </div>
                )}
                <MiniHeader
                  marginTop={20}
                  onClick={() =>
                    this.setState({
                      playerRegistrationOptionsOpen:
                        !this.state.playerRegistrationOptionsOpen,
                    })
                  }
                  iconName={
                    this.state.playerRegistrationOptionsOpen
                      ? "chevrondown"
                      : "chevronright"
                  }
                  tabIndex="0"
                  id="contactOptions"
                  scrollOnClick={!this.state.playerRegistrationOptionsOpen}
                >
                  Player Registration Options
                </MiniHeader>
                {this.state.playerRegistrationOptionsOpen && (
                  <div className="form-divided-section">
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderCheckbox(
                          "freeAgentLinkActive",
                          "Free Agent Registration",
                          "",
                          {
                            header: "Free Agent Registration",
                            text: `All leagues are provided with a link to their Free Agent registration page.
                            Toggle off to prevent usage of the Free Agent registration link.
                            All players will have to be invited by an admin or a team captain.`,
                            direction: "right",
                            className: "icon-mobile",
                          }
                        ),
                        this.renderCheckbox(
                          "teamCaptainLinkActive",
                          "Team Captain Direct Registration",
                          "",
                          {
                            header: "Team Captain Direct Registration",
                            text: `All leagues are provided with a link to their Team Captain registration page.
                            Toggle off to prevent usage of the Team Captain registration link.
                            All Team Captains will have to be invited by an admin.`,
                            direction: "right",
                            className: "icon-mobile",
                          }
                        ),
                      ]}
                    />
                    <hr className="custom" />
                    <div>
                      <h6 className="text-center">
                        Additional questions to ask during player registration
                      </h6>
                      <p>
                        By default during player registration we only ask for an
                        email, a name, and a password for the user account. Use
                        the options below to add further information to be
                        gathered during signup. Admins will be able to view this
                        information. Answering any of the questions will be
                        optional for the player.
                      </p>
                    </div>
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderCheckbox(
                          "shirtSizeRequired",
                          "Ask Shirt Size",
                          "",
                          {
                            header: "Ask Player Shirt Size",
                            text: `Player will be give the option to select their shirt size during signup.
                            Size options range from XS to XXL.`,
                            direction: "right",
                            className: "icon-mobile",
                          }
                        ),
                        null,
                      ]}
                    />
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderCheckbox(
                          "genderIdentityRequired",
                          "Ask Gender Identity",
                          "",
                          {
                            header: "Ask Gender Identity",
                            text: `Player will be give the option to select their gender identity upon signup.
                            Options displayed will be: Male, Female, Non-Binary, Prefer not to say.`,
                            direction: "right",
                            className: "icon-mobile",
                          }
                        ),
                        this.renderCheckbox(
                          "pronounsRequired",
                          "Ask Pronouns",
                          "",
                          {
                            header: "Ask Pronouns",
                            text: `Player will be give the option to enter their preferred pronouns upon signup.
                            This field will be optional.`,
                            direction: "right",
                            className: "icon-mobile",
                          }
                        ),
                      ]}
                    />
                    {(this.state.data.genderIdentityRequired ||
                      this.state.data.pronounsRequired ||
                      this.state.data.shirtSizeRequired) &&
                      this.renderTextArea(
                        "informationRequestReason",
                        "Reason for asking additional questions",
                        "",
                        "2",
                        {
                          header: "Gender and pronoun requirement reasons",
                          text: `To make your players more comfortable during signup you can provide the reason
                          you would like to know the answers for the additional signup questions.
                          For example to gather sizes for a free shirt, or to ensure even gender split in coed leagues.
                          This will display to the player before they make their selection.`,
                          direction: "right",
                          className: "icon-mobile",
                        },
                        false,
                        "e.g. Please select your shirt size for your free tournament t-shirt"
                      )}
                  </div>
                )}
                <MiniHeader
                  marginTop={20}
                  onClick={() =>
                    this.setState({
                      userInteractionsOpen: !this.state.userInteractionsOpen,
                    })
                  }
                  iconName={
                    this.state.userInteractionsOpen
                      ? "chevrondown"
                      : "chevronright"
                  }
                  tabIndex="0"
                  id="userInteractions"
                  scrollOnClick={!this.state.userInteractionsOpen}
                >
                  User Interactions
                </MiniHeader>
                {this.state.userInteractionsOpen && (
                  <div className="form-divided-section">
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderCheckbox(
                          "userImages",
                          "User Images",
                          "",
                          {
                            header: "User Images",
                            text: `Select Yes to allow all users to upload their profile image. Referee images will display next to the matches they are taking charge of.
                Player images will appear on the Leaderboards and Scorecard for easy identification by the referee.
                This setting also allows team captains to upload their team logo.
                You may switch this setting back and forth without effecting images that have already been uploaded.
                Switching to No makes current images not visible and prevents uploading of new images. Switching back to Yes makes all previously uploaded images visible again.
                If you have a problem with certain users uploading inappropriate images please contact support.
                It is possible for site admins to block individual image uploading on a case by case basis.`,
                            direction: "right",
                            className: "icon-mobile",
                          },
                          true
                        ),
                        this.renderCheckbox(
                          "requireVaccine",
                          "Submit Vaccine Information",
                          "",
                          {
                            header: "Require Vaccine Information",
                            text: `If you select Yes then all users will be given an option on their Profile drop down to submit thier vaccination information.
                  The form will ask for the date and name of their vaccination,
                  and offer an email link prompting them to send a photo of their vaccine card to the League owner.
                  All admins will have access to a dashboard where they can mark that user's vaccine information as reviewed.
                  It will be up to the League admins to review and accept the validity of any information provided.`,
                            direction: "right",
                            className: "icon-mobile",
                          },
                          false
                        ),
                      ]}
                    />
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderCheckbox(
                          "profanityFilter",
                          "Profanity Filter",
                          "",
                          {
                            header: "Profanity Filter",
                            text: `For the team messaging system you can decide whether or not profanity will be filtered (shown as ****).
                            This feature uses a standard list of disallowed words so we cannot guarantee it will catch creative attempts to circumvent it.`,
                            direction: "right",
                            className: "icon-mobile",
                          },
                          true
                        ),
                        this.renderCheckbox(
                          "captainApproval",
                          "Captain Approval and Comments",
                          "",
                          {
                            header: "Approvals",
                            text: `Before submitting a match the ${refOrUmp.toLowerCase()} will need to show the final result to the team captains and mark their approval or protest.
                            Protested matches will be highlighted on the review screen. Captains will have an opportunity to add a comment after each match.
                            Comments and approvals can be viewed by all registered players but are not visible on the stats tab of your webpage.
                            ${refOrUmp}s will be able to leave a comment on their submitted scorecard no matter which option is selected.`,
                            direction: "right",
                            className: "icon-mobile",
                          },
                          true
                        ),
                      ]}
                    />
                    {this.state.data.captainApproval &&
                      this.renderTextArea(
                        "commentHint",
                        "Comment Hint",
                        "",
                        3,
                        {
                          header: "Comment Hint",
                          text: `This is the placeholder text that will display in the captain comment box after a scorecard is submitted.
                          Use this text to ask the captain for specific information when writing their comment.
                          If left blank the default text will display.`,
                          direction: "right",
                          className: "icon-mobile",
                        },
                        false,
                        allowables.defaultCommentHint
                      )}
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderCheckbox(
                          "liveScorecards",
                          "Live Scorecards",
                          "",
                          {
                            header: "Live Scorecards",
                            text: `Enabling this option will allow users and spectators to follow along with live match events.
                          All inputs by the referee/umpire will be immediately available for viewing,
                          This feature can replace the need for updating a large central scoreboard as all the information will be available in real time on the app.`,
                            direction: "right",
                            className: "icon-mobile",
                          },
                          true
                        ),
                        this.renderCheckbox(
                          "userReportedStats",
                          "User Reported Events",
                          "",
                          {
                            header: "User Reported Events (BETA)",
                            text: `Allow team captains to update entered events on the scorecard.
                            This feature allows you to take the burden of player tracking off your ${allowables.refOrUmp(
                              user.sport
                            )}s.
                            The ${allowables.refOrUmp(
                              user.sport
                            )} will enter the event as usual, but the team captain will be able to update the players to which the event belongs.`,
                            direction: "right",
                            className: "icon-mobile",
                          },
                          true
                        ),
                      ]}
                    />
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderCheckbox(
                          "waiverWireOn",
                          "Waiver Wire On",
                          "",
                          {
                            header: "Waiver Wire (BETA)",
                            text: `Our waiver wire system allows for team captains and players to manage their own recruitment of Free Agents.
                        For more information on how the system operates please see the help section or contact our helpdesk.`,
                            direction: "right",
                            className: "icon-mobile",
                          },
                          true
                        ),
                        this.state.data.waiverWireOn &&
                          this.renderInput(
                            "waiverWireLength",
                            "Waiver Wire Length",
                            "",
                            "number",
                            "",
                            7,
                            1,
                            {
                              header: "Waiver Wire Length (Days)",
                              text: `The waiver wire exists for players who transfer themselves off their team or are transfered to Free Agents by the team captain.
                        While a player is on the waiver wire a captain can put in a bid for the player and the team with the highest priority when the waiver ends will recruit that player.
                        When transferring themselves to Free Agents a player can opt to skip the waiver wire.
                        The minimum and default length is one day. Maximum length is 7 days`,
                              direction: "right",
                              className: "icon-mobile",
                            },
                            "",
                            true
                          ),
                      ]}
                    />
                  </div>
                )}
                <MiniHeader
                  marginTop={20}
                  onClick={() =>
                    this.setState({
                      contactOptionsOpen: !this.state.contactOptionsOpen,
                    })
                  }
                  iconName={
                    this.state.contactOptionsOpen
                      ? "chevrondown"
                      : "chevronright"
                  }
                  tabIndex="0"
                  id="contactOptions"
                  scrollOnClick={!this.state.contactOptionsOpen}
                >
                  League Contact Information
                </MiniHeader>
                {this.state.contactOptionsOpen && (
                  <div className="form-divided-section">
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderInput(
                          "customerServiceEmail",
                          "Customer Service Email",
                          "",
                          "",
                          "",
                          "",
                          "",
                          {
                            header: "Customer Service Email",
                            text: `Enter a specific email here to direct all user customer service requests.
                        This will populate on your webpage and when using the Contact Us form.
                        If left blank then the default customer service email will be your email.`,
                            direction: "right",
                            className: "icon-mobile",
                          },
                          "",
                          false,
                          ""
                        ),
                        this.renderInput(
                          "customerServicePhoneNumber",
                          "Customer Service Phone Number",
                          "",
                          "",
                          "",
                          "",
                          "",
                          {
                            header: "Customer Service Phone Number",
                            text: `Enter a phone number here to direct all user customer service requests.
                        This will populate on your webpage and when using the Contact Us form.
                        If left blank then no phone number will populate.`,
                            direction: "right",
                            className: "icon-mobile",
                          },
                          "",
                          false,
                          ""
                        ),
                      ]}
                    />
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.state.data.requireVaccine &&
                          this.renderInput(
                            "vaccinationEmail",
                            "Vaccination Card Email",
                            "",
                            "",
                            "",
                            "",
                            "",
                            {
                              header: "Vaccination Card Email",
                              text: `If you would like to user a separate email to receive user vaccination proof enter it here.
                        If no email here is entered then it will revert to your customer service email,
                        followed by the league owner email.`,
                              direction: "right",
                              className: "icon-mobile",
                            },
                            "",
                            false,
                            ""
                          ),
                      ]}
                    />
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderInput(
                          "leagueWebsiteAddress",
                          "External Website",
                          "",
                          "",
                          "",
                          "",
                          "",
                          {
                            header: "External Website",
                            text: `Enter your website address if you maintain your own external site.
                        This will populate on your webpage and when using the Contact Us form.
                        It can be useful to make this a direct link to a contact form.
                        If left blank then no website will populate.`,
                            direction: "right",
                            className: "icon-mobile",
                          },
                          "",
                          false,
                          ""
                        ),
                        this.renderInput(
                          "spectatingAllowed",
                          "Hosted Webpage Extension",
                          "",
                          "",
                          "",
                          "",
                          "",
                          {
                            header: "Hosted Webpage Extension",
                            text: `This is where your data will be hosted on Ultimate Scoreboard.
                Registered users and non users alike will be able to view League data and Matches at this address.
                Please enter a minimum of 3 characters and a maximum of 55. For example if your League is called The Los Angeles Soccer Championship you might put lasoccer.
                Your data will then be hosted at ultimatescoreboard.com/lasoccer.
                Addresses must be unique from any other League and are distributed on a first come first served basis.
                If you do not want your League data publically available then leave this field blank.`,
                            direction: "right",
                            className: "icon-mobile",
                          },
                          "",
                          true,
                          ""
                        ),
                      ]}
                    />
                  </div>
                )}
                <MiniHeader
                  marginTop={20}
                  onClick={() =>
                    this.setState({
                      adminOptionsOpen: !this.state.adminOptionsOpen,
                    })
                  }
                  iconName={
                    this.state.adminOptionsOpen ? "chevrondown" : "chevronright"
                  }
                  tabIndex="0"
                  id="adminOptions"
                  scrollOnClick={!this.state.adminOptionsOpen}
                >
                  Admin Options
                </MiniHeader>
                {this.state.adminOptionsOpen && (
                  <div className="form-divided-section">
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderCheckbox(
                          "allowMatchSelfAssignment",
                          `Allow ${refOrUmp}s to Self-assign Scorecards`,
                          "",
                          {
                            header: "Self-Assign Scorecards",
                            text: `Allow ${refOrUmp}s to "take" a scorecard when it is not assigned to them,
                            allowing more flexibility in scorecard assignment.
                            Live Scorecards must also be enabled to use this feature.`,
                            direction: "right",
                            className: "icon-mobile",
                          },
                          true
                        ),
                        this.state.data.captainApproval
                          ? this.renderSelect(
                              "defaultApproval",
                              "Default Approval Options",
                              [
                                { _id: "Fully Approve", name: "Fully Approve" },
                                {
                                  _id: "Remove Comments",
                                  name: "Remove Comments",
                                },
                                {
                                  _id: "Both",
                                  name: "Remove Comments and Fully Approve",
                                },
                                { _id: "Neither", name: "Neither" },
                              ],
                              "",
                              {
                                header: "Default Approval Options",
                                text: `When approving a match there will be two checkboxes available to decide how to deal with captain comments and approvals.
                          To save time this will set up what your normal approval would be.
                          These boxes can be checked or unchecked for each match regardless of what is decided here.
                          Fully Approve means that both team approvals will be set to approved.
                          Remove Comments will delete all captain and referee comments from the scorecard.`,
                                direction: "right",
                                className: "icon-mobile",
                              }
                            )
                          : null,
                      ]}
                    />
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderSelect(
                          "rankingType",
                          "Ranking Type",
                          [
                            { _id: "Stats", name: "Stats" },
                            { _id: "Wins", name: "Wins" },
                            { _id: "Elo", name: "Elo" },
                          ],
                          "",
                          {
                            header: "Ranking Type",
                            text: `This determines how teams will be separated on the division tables when they are tied on points or W/L record.
                          "Stats": The primary separator will be goals/points differential, followed by goals/points scored, finally by results between the tied teams.
                          "Wins": The primary separator will be results between the teams. This is often used to prevent teams from  feeling that they need to run up the score against weaker opponents to acheive a better ranking.
                          "Elo": Use the Elo rankings system to rank teams`,
                            direction: "right",
                            className: "icon-mobile",
                          }
                        ),
                        this.state.data.rankingType === "Elo" &&
                          this.renderInput(
                            "eloKFactor",
                            "Elo K Factor",
                            "",
                            "number",
                            "",
                            "50",
                            "10",
                            {
                              header: "Elo K Factor",
                              text: `The setting here will change how much a single victory effects a teams standing.
                            Set a high K factor to allow larger swings in rankings, lower to have more stability.
                            Standings are calculated on the fly so this setting can be changed at any time and all previous results will update.
                            Due to the way that the Elo system works, you should set a date and time for each match to prevent varying results.
                            Minimum value is 10, maximum is 50.
                            The default is 32.`,
                              direction: "right",
                              className: "icon-mobile",
                            }
                          ),
                      ]}
                    />
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderInput(
                          "pointsPerWin",
                          "Points per Win",
                          "",
                          "number",
                          "",
                          "999",
                          "0",
                          {
                            header: "Points per Win",
                            text: `For custom scoring systems enter the number of points to be awarded for a win.
                          The default is 3.`,
                            direction: "right",
                            className: "icon-mobile",
                          }
                        ),
                        this.renderInput(
                          "pointsPerTie",
                          "Points per Tie",
                          "",
                          "number",
                          "",
                          "999",
                          "0",
                          {
                            header: "Points per Tie",
                            text: `For custom scoring systems enter the number of points to be awarded for a tie.
                          The default is 1.`,
                            direction: "right",
                            className: "icon-mobile",
                          }
                        ),
                        this.renderInput(
                          "pointsPerLoss",
                          "Points per Loss",
                          "",
                          "number",
                          "",
                          "999",
                          "0",
                          {
                            header: "Points per Loss",
                            text: `For custom scoring systems enter the number of points to be awarded for a loss.
                          The default is 0.`,
                            direction: "right",
                            className: "icon-mobile",
                          }
                        ),
                      ]}
                    />
                    <SideBySideView
                      doNotCenterOnStack={true}
                      minWidth={minWidth}
                      Components={[
                        this.renderInput(
                          "customFlag1",
                          "Custom Flag 1",
                          "",
                          "string",
                          "",
                          "",
                          "",
                          {
                            header: "Custom Flags",
                            text: `These flags are custom named booleans (true or false) that you can apply to every user in your league.
                        Any admin will have the ability to update these flags for any user.
                        An example of use would be requiring check-in at the field.
                        An admin or staff member can check players off using these flags as they arrive,
                        and then any admin is able to view the status of the flags.
                        Leave the field blank and they will not be used.`,
                            direction: "right",
                            className: "icon-mobile",
                          }
                        ),
                        data.customFlag1
                          ? this.renderInput(
                              "customFlag2",
                              "Custom Flag 2",
                              "",
                              "string",
                              "",
                              "",
                              "",
                              {
                                header: "Custom Flags",
                                text: `The second Custom Flag is only available if you are using the first one.
                              These flags are custom named booleans (true or false) that you can apply to every user in your league.
                              Any admin will have the ability to update these flags for any user.
                              An example of use would be requiring check-in at the field.
                              An admin or staff member can check players off using these flags as they arrive,
                              and then any admin is able to view the status of the flags.
                              Leave the field blank and they will not be used.`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              "",
                              "",
                              ""
                            )
                          : null,
                      ]}
                    />
                  </div>
                )}
                <MiniHeader
                  marginTop={20}
                  onClick={() =>
                    this.setState({
                      mvpOptionsOpen: !this.state.mvpOptionsOpen,
                    })
                  }
                  iconName={
                    this.state.mvpOptionsOpen ? "chevrondown" : "chevronright"
                  }
                  tabIndex="0"
                  id="mvpOptions"
                  scrollOnClick={!this.state.mvpOptionsOpen}
                >
                  Award Voting Options&nbsp;&nbsp;
                  <NewFeatureBadge inverse={true} />
                </MiniHeader>
                {this.state.mvpOptionsOpen && (
                  <div className="form-divided-section">
                    <p className="text-center">
                      Add up to four categories to allow players to vote for MVP
                      within Ultimate Scoreboard. League Admins will have access
                      to a dashboard to view vote tallies.
                    </p>
                    {this.renderMultiFieldInput(
                      "Award Categories",
                      [
                        {
                          name: "name",
                          type: "input",
                          style: { fontWeight: "bold" },
                        },
                        {
                          name: "singleVote",
                          type: "checkbox",
                          style: { fontWeight: "bold" },
                          label: "Single Vote",
                        },
                        {
                          name: "canVoteForOtherTeams",
                          type: "checkbox",
                          style: { fontWeight: "bold" },
                          label: "Allow Voting for Other Teams",
                        },
                        {
                          name: "canVoteOutsideDivision",
                          type: "checkbox",
                          style: { fontWeight: "bold" },
                          label: "Allow Voting Outside Division",
                        },
                        {
                          name: "canVoteForOwnTeam",
                          type: "checkbox",
                          style: { fontWeight: "bold" },
                          label: "Allow Voting for Own Team",
                        },
                        {
                          name: "isLocked",
                          type: "checkbox",
                          style: { fontWeight: "bold" },
                          label: "Lock Category",
                        },
                      ],
                      "categories",
                      false,
                      "MVP Category",
                      4
                    )}
                    <p className="text-center">
                      <small>
                        Making changes to MVP category names after votes have
                        been cast is not advised as it has the potential to
                        cause issues with the tallies
                      </small>
                    </p>
                  </div>
                )}
                <MiniHeader
                  marginTop={20}
                  onClick={() =>
                    this.setState({
                      externalLinksOpen: !this.state.externalLinksOpen,
                    })
                  }
                  iconName={
                    this.state.externalLinksOpen
                      ? "chevrondown"
                      : "chevronright"
                  }
                  tabIndex="0"
                  id="externalLinks"
                  scrollOnClick={!this.state.externalLinksOpen}
                >
                  External Links
                </MiniHeader>
                {this.state.externalLinksOpen && (
                  <div className="form-divided-section">
                    <p className="text-center">
                      These links will show for every user in their Profile
                      dropdown navigator and on your league webpage towards the
                      top of the page.
                      <br />
                      Provide a link to your merchandise page, or to more
                      information about the local area.
                    </p>
                    {this.renderMultiFieldInput(
                      "External Links",
                      [
                        {
                          name: "text",
                          type: "input",
                          style: { fontWeight: "bold" },
                        },
                        {
                          name: "url",
                          type: "input",
                          style: { fontWeight: "bold" },
                        },
                      ],
                      "externalLinks",
                      false,
                      "External Link",
                      4
                    )}
                    <br />
                    <p className="text-center">
                      <small>
                        Ultimate Scoreboard reserves the right to remove any
                        external links deemed inappropriate or in violation of
                        site policy.
                      </small>
                    </p>
                  </div>
                )}
                {this.state.data.sport.toLowerCase().includes("soccer") && (
                  <React.Fragment>
                    <MiniHeader
                      marginTop={20}
                      onClick={() =>
                        this.setState({
                          soccerOptionsOpen: !this.state.soccerOptionsOpen,
                        })
                      }
                      iconName={
                        this.state.soccerOptionsOpen
                          ? "chevrodown"
                          : "chevronright"
                      }
                      tabIndex="0"
                      id="suspensions"
                      scrollOnClick={!this.state.soccerOptionsOpen}
                    >
                      Soccer Options
                    </MiniHeader>
                    {this.state.soccerOptionsOpen && (
                      <div className="form-divided-section">
                        <p className="text-center">
                          If you do not want to track suspensions set all fields
                          in this section to 0/No
                        </p>
                        <SideBySideView
                          doNotCenterOnStack={true}
                          minWidth={minWidth}
                          Components={[
                            this.renderInput(
                              "redCard",
                              "Suspension for Red Card",
                              "",
                              "number",
                              "",
                              9,
                              0,
                              {
                                header: "Red Cards",
                                text: `Number of match suspension a player receives for a straight red card`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              "1",
                              true
                            ),
                            this.renderInput(
                              "twoYellowsInGame",
                              "Suspension for Two Yellow Cards",
                              "",
                              "number",
                              "",
                              9,
                              0,
                              {
                                header: "Yellow Cards",
                                text: `Number of match suspension a player receives for accumulating two yellow cards in a single match`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              "1",
                              true
                            ),
                          ]}
                        />
                        <SideBySideView
                          doNotCenterOnStack={true}
                          minWidth={minWidth}
                          Components={[
                            this.renderInput(
                              "yellowsInTournament",
                              "Accumulated Yellow Cards Required for One Game Suspension",
                              "",
                              "number",
                              "",
                              25,
                              0,
                              {
                                header: "Accumulated Yellow Cards",
                                text: `Number of accumulated yellow cards required throughout the season or tournament to receive a one match suspension`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              "1",
                              true
                            ),
                            this.renderCheckbox(
                              "accumulateYellows",
                              "Accumulate Two Yellows in One Match",
                              "",
                              {
                                header: "Acuumulate Two Yellow Cards",
                                text: `If a player receives two yellow cards in a single match they will receive a ${this.state.data.twoYellowsInGame} match suspension.
                              Should those yellow cards also count towards total accumulated yellow cards for the season or tournament?`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              true
                            ),
                          ]}
                        />
                        <SideBySideView
                          doNotCenterOnStack={true}
                          minWidth={minWidth}
                          Components={[
                            this.renderCheckbox(
                              "trackAssists",
                              "Track Assists",
                              "",
                              {
                                header: "Track Assists",
                                text: `Allow your referees to select the player who assisted the goal, and have this tracked in player stats.`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              true
                            ),
                          ]}
                        />
                        <SideBySideView
                          doNotCenterOnStack={true}
                          minWidth={minWidth}
                          Components={[
                            this.renderInput(
                              "pointsPerCleanSheet",
                              "Points per Clean Sheet",
                              "",
                              "number",
                              "",
                              "999",
                              "0",
                              {
                                header: "Points per Win",
                                text: `For custom scoring systems enter the number of bonus points to be awarded for keeping a clean sheet.
                          The default is 0.`,
                                direction: "right",
                                className: "icon-mobile",
                              }
                            ),
                            this.renderInput(
                              "maxBonusPerGoal",
                              "Max Bonus Points per Goal in Match",
                              "",
                              "number",
                              "",
                              "999",
                              "0",
                              {
                                header: "Max Bonus Points per Goal",
                                text: `For custom scoring systems you can award bonus points for each goal scored.
                                Enter the maximum number of bonus points to be awarded per goal in each match.
                          The default is 0.`,
                                direction: "right",
                                className: "icon-mobile",
                              }
                            ),
                          ]}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )}
                {[
                  "Baseball",
                  "Softball",
                  "Kickball",
                  "Diamond Sports",
                ].includes(mappedSportName) && (
                  <div>
                    <MiniHeader
                      marginTop={20}
                      onClick={() =>
                        this.setState({
                          diamondSportOptionsOpen:
                            !this.state.diamondSportOptionsOpen,
                        })
                      }
                      iconName={
                        this.state.diamondSportOptionsOpen
                          ? "chevronup"
                          : "chevrondown"
                      }
                      tabIndex="0"
                      id="diamondSportOptions"
                      scrollOnClick={!this.state.diamondSportOptionsOpen}
                    >
                      {mappedSportName} Options
                    </MiniHeader>
                    {this.state.diamondSportOptionsOpen && (
                      <div className="form-divided-section">
                        <SideBySideView
                          doNotCenterOnStack={true}
                          minWidth={minWidth}
                          Components={[
                            this.renderInput(
                              "outsPerInning",
                              "Outs per Inning",
                              "",
                              "number",
                              "",
                              9,
                              1,
                              {
                                header: "Outs per Inning",
                                text: `This value will be used to indicate on the Scorecard when an inning has ended,
                                and is used to calculate the box score display, number of innings pitched, etc.`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              "1",
                              true
                            ),
                            this.renderInput(
                              "inningsPerGame",
                              "Innings per Game",
                              "",
                              "number",
                              "",
                              15,
                              1,
                              {
                                header: "Innings per Game",
                                text: `This is used for ERA calculations.`,
                                direction: "right",
                                className: "icon-mobile",
                              },
                              "1",
                              true
                            ),
                          ]}
                        />
                        {this.renderCheckbox(
                          "hideFoulBalls",
                          "Hide Foul Balls",
                          "",
                          {
                            header: "Hide Foul Balls",
                            text: `This is used purely for display purposes.
                                If selected an umpire will not be able to track foul balls on the scorecard.
                                If foul balls are not needed to be specifically tracked in your sport then this can serve to clean up the scorecard and make life easier for your umpires.`,
                            direction: "right",
                            className: "icon-mobile",
                          },
                          true
                        )}
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            )}
            <br />
            {this.renderValidatedButton("Submit")}
            <br />
            <br />
          </form>
        )}
      </div>
    );
  }
}

export default OrgForm;

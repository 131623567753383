import React from "react";
import { format } from "date-fns";
import IconRender from "../components/common/icons/iconRender";

const url =
  process.env.NODE_ENV === "production"
    ? "https://ultimatescoreboard.com"
    : "http://localhost:3000";

const supportEmail = "accounts@ultimatescoreboard.com";

const allowables = {
  url,
  supportEmail,
  sports: (splitDiamondSports) => {
    const sports = [
      { _id: "football", name: "Football" },
      { _id: "soccer", name: "Soccer" },
      { _id: "simple", name: "Generic" },
    ];
    if (splitDiamondSports) {
      sports.splice(1, 0, { _id: "kickball", name: "Kickball" });
      sports.splice(2, 0, { _id: "softball", name: "Softball" });
      sports.unshift({ _id: "baseball", name: "Baseball" });
      sports.splice(5, 0, { _id: "tennis", name: "Tennis" });
    } else {
      sports.unshift({ _id: "diamond", name: "Diamond Sports" });
    }
    return sports;
  },

  ballIcon: function (sport, className, overrideNoSport) {
    if (!sport)
      return (
        <IconRender
          name={overrideNoSport || "userSettings"}
          className={className}
        />
      );

    const lower = sport.toLowerCase();
    return lower.includes("soccer") ? (
      <IconRender name="soccer" className={className} />
    ) : lower.includes("football") ? (
      <IconRender name="football" className={className} />
    ) : lower.includes("baseball") ? (
      <IconRender name="baseballbat" className={className} />
    ) : lower.includes("softball") ? (
      <IconRender name="baseballbatthick" className={className} />
    ) : lower.includes("kickball") ? (
      <IconRender name="kickball" className={className} />
    ) : lower.includes("tennis") ? (
      <IconRender name="tennis" className={className} />
    ) : lower.includes("simple") ? (
      <IconRender name="simple" className={className} />
    ) : (
      <IconRender
        name={overrideNoSport || "userSettings"}
        className={className}
      />
    );
  },
  dateTime: function (matchDateTime, dontSlice) {
    try {
      if (!matchDateTime) return "";
      const newDateTime = new Date(
        dontSlice || typeof matchDateTime !== "string"
          ? matchDateTime
          : matchDateTime.slice(0, matchDateTime.length - 1)
      );
      return {
        date: format(newDateTime, "MMM dd, yyyy"),
        time: format(newDateTime, "h:mm a").toLowerCase(),
      };
    } catch (ex) {
      return "";
    }
  },
  shortDate: function (matchDateTime, dontSlice) {
    try {
      if (!matchDateTime) return "";
      const newDateTime = new Date(
        dontSlice || typeof matchDateTime !== "string"
          ? matchDateTime
          : matchDateTime.slice(0, matchDateTime.length - 1)
      );
      return format(newDateTime, "M/d/yy h:mma").toLowerCase();
    } catch (ex) {
      return "";
    }
  },
  convertTime: function (timestamp) {
    return Number.isInteger(timestamp)
      ? format(timestamp, "h:mma").slice(0, -1).toLowerCase()
      : "";
  },
  formDate: function (dateString, noTime, alreadyDate) {
    if (!dateString) return null;
    if (alreadyDate)
      return (
        format(dateString, "yyyy-MM-dd") +
        (noTime ? "" : "T" + format(dateString, "HH:mm"))
      );
    const newDate = new Date(dateString.slice(0, dateString.length - 1));
    const newTime = new Date(dateString.slice(0, dateString.length - 1));
    const dateTime =
      format(newDate, "yyyy-MM-dd") +
      (noTime ? "" : "T" + format(newTime, "HH:mm"));
    return dateTime;
  },
  pageHeaderConversion: function (pathname) {
    return pathname.includes("/divisions")
      ? "Divisions"
      : pathname.includes("/league")
      ? "League"
      : pathname.includes("/fields")
      ? "Fields"
      : pathname.includes("/teams")
      ? "Teams"
      : pathname.includes("/discount")
      ? "Discounts"
      : pathname.includes("/tactics")
      ? "Tactics"
      : pathname.includes("/addplayers")
      ? "Add Players"
      : pathname.includes("/players")
      ? "Players"
      : pathname.includes("/awards")
      ? "Award Voting"
      : pathname.includes("/reports")
      ? "Current Stats"
      : pathname.includes("/matches")
      ? "Upcoming Scorecards"
      : pathname.includes("/create")
      ? "Create Schedule"
      : pathname.includes("/assigned")
      ? "Scorecards"
      : pathname.includes("/profile")
      ? "Profile"
      : pathname.includes("/subscription")
      ? "Subscription"
      : pathname.includes("/payments")
      ? "League Dues"
      : pathname.includes("/history")
      ? "My History"
      : pathname.includes("/offcyclepayments")
      ? "Payments"
      : pathname.includes("/matchreview")
      ? "Scorecard Review"
      : pathname.includes("/help")
      ? "Help"
      : pathname.includes("/vaccine")
      ? "Vaccine"
      : pathname.includes("/codeofconduct")
      ? "Code of Conduct"
      : pathname.includes("/started")
      ? "Getting Started"
      : pathname.includes("/features")
      ? "Features"
      : pathname.includes("/userimages")
      ? "Images"
      : pathname.includes("/seasons")
      ? "Seasons"
      : pathname.includes("/archive")
      ? "Archive"
      : pathname.includes("/customflags")
      ? "Custom Flags"
      : pathname.includes("/scorecards")
      ? "Live Scorecards"
      : pathname.includes("/scoreboard")
      ? "Scoreboards"
      : pathname.includes("/messages")
      ? "Message Center"
      : pathname.includes("/gettingstarted")
      ? "Quick Start"
      : pathname.includes("/privacy")
      ? "Sitewide Policies"
      : "";
  },
  teams: function (sport) {
    return ["soccer", "simple"].includes(sport.toLowerCase())
      ? ["home", "away"]
      : ["away", "home"];
  },
  pageSize: 30,
  dummyTeam: {
    _id: "5f2d7856f335732f275be08e",
    name: "Select Team",
    teamAbbreviation: "M#",
  },
  cookieOptions: {
    expires: 7,
    sameSite: "Lax",
  },
  fallbackImage: (aspectRatio = 1) => {
    return aspectRatio === 1
      ? "/logos/iconOnSplash.png"
      : aspectRatio === 3
      ? "/logos/stackedLogo.png"
      : aspectRatio === 8
      ? "/logos/spreadLogo.png"
      : "";
  },
  capLetterOne: function (string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  splitName: function (name) {
    if (!name) return "";
    return name.split("%20%").join(" ");
  },
  atOrVs: function (teamOrder) {
    return teamOrder[0] === "home" ? "vs" : "at";
  },
  refOrUmp: function (sport) {
    if (sport && sport.toLowerCase().includes("soccer")) return "Referee";
    return "Umpire";
  },
  isMobile: function () {
    return window.screen.width < 768;
  },
  matchTypes: ["Regular Season", "Group", "Seeding", "Playoff", "Friendly"],
  getTextColor: function (hex) {
    if (!hex) return null;
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      return null;
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    // convert color to black or white
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#ffffff";
  },
  defaultCommentHint: `Use this box to notify the league/tournament admin of any needed changes or protests to the result. Comments written here can be viewed by all admins and players so please be respectful.`,
  translateRole: function (role, sport) {
    return role?.includes("owner")
      ? "League Owner"
      : role?.includes("captain")
      ? "Team Captain"
      : role?.includes("referee")
      ? this.refOrUmp(sport)
      : role?.includes("manager")
      ? "League Manager"
      : role?.includes("admin")
      ? "League Admin"
      : role?.charAt(0).toUpperCase() + role?.slice(1);
  },
  translateSportName: function (org) {
    let lcSport = org?.sport?.toLowerCase();
    return lcSport?.includes("diamond")
      ? org?.misc?.diamondSportOptions?.sportName
      : lcSport?.includes("simple")
      ? org?.misc?.simpleScoringOptions?.scorecardType || org?.sport
      : org?.sport || "";
  },
  getLiveScorecardUrl: (matchID, org) => {
    if (
      !matchID ||
      !org?.spectatingAllowed ||
      !org.misc?.liveScorecards ||
      org.spectatingAllowed.includes("blocked")
    )
      return "";
    return `${url}/deeplink?pathname=${org.spectatingAllowed}&live&matchID=${matchID}`;
  },
};

export default allowables;

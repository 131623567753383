import React, { useState, useEffect } from "react";
import saveSvgAsPng from "save-svg-as-png";

import useWindowDimensions from "../../utils/useWindowDimensions";
import { getRoundName, separateAndSplit } from "../../utils/bracketsUtil";
import SingleMatch from "./singleMatch";
import MatchConnector from "./matchConnector";
import BracketButtons from "./bracketButtons";
import BracketFinals from "./bracketFinals";
import WarningHeader from "../common/pageComponents/warningHeader";

/* 
  TODO: Bracket height and match size should scale with number of matches
  Look at the released package for how this should be done
*/

const PlayoffBracketCanvas = ({
  matches,
  spectate,
  onSelectMatch,
  hideFormatter,
  containerStyle = {},
}) => {
  const { width, height } = useWindowDimensions();
  const [orientation, setOrientation] = useState("portrait");
  const [selectedBracket, setSelectedBracket] = useState("main");
  const [bracketSize, setBracketSize] = useState({
    width: width < 1280 ? width : 1280,
    height: height < 720 ? height : 720,
    matchHeight: 100,
  });
  const [showFullTeamNames, setShowFullTeamNames] = useState(false);

  useEffect(() => {
    if (!matches.length) return;
    const bracket = separateAndSplit(allRounds, selectedBracket, matches);

    let maxHeight = 0;
    bracket.forEach((round) => {
      if (round.length > maxHeight) maxHeight = round.length;
    });

    let bHeight = Math.max(
      maxHeight * (orientation === "landscape" ? 60 : 120),
      height * 0.8
    );
    if (allRounds.losersFinal) bHeight += 120;
    const bWidth = hideFormatter
      ? width < bracket.length * 100
        ? bracket.length * 100
        : Math.min(width - 50, containerStyle.maxWidth || 0)
      : width * 0.9;

    setBracketSize({
      width: bWidth,
      height: bHeight,
      matchHeight: 100,
      ...containerStyle,
    });
    if (hideFormatter) setOrientation("portrait");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBracket, matches.length, orientation]);

  useEffect(() => {
    setSelectedBracket("main");
  }, [matches.length]);

  if (matches.length === 0)
    return <WarningHeader>There are no brackets to show</WarningHeader>;

  const downloadBracketImage = async () => {
    saveSvgAsPng.saveSvgAsPng(
      document.getElementById("svg-bracket"),
      matches[0].divisionName + "_bracket.png"
    );
  };

  const allRounds = {
    main: [
      ...new Set(
        matches
          .filter((m) => m.round > 0 && m.round <= 10)
          .map((m) => m.round)
          .sort((a, b) => a - b)
      ),
    ],
    secondary: [
      ...new Set(
        matches
          .filter(
            (m) =>
              m.round > 10 &&
              m.round !== 99 &&
              m.round !== 100 &&
              m.round !== 999 &&
              m.round !== 101
          )
          .map((m) => m.round)
          .sort((a, b) => a - b)
      ),
    ],
    prelim: [
      ...new Set(
        matches
          .filter((m) => m.round === 0)
          .map((m) => m.round)
          .sort((a, b) => a - b)
      ),
    ],
    secondFinal: matches.find((m) => m.round === 99),
    thirdFinal: matches.find((m) => m.round === 100),
    thirdPlacePlayoff: matches.find((m) => m.round === 101),
    losersFinal: matches.find((m) => m.round === 999),
  };

  const getRounds = () => {
    const final = Math.max(...allRounds[selectedBracket]);
    return {
      final,
      semi: final - 1,
    };
  };

  const renderBracket = () => {
    const bracket = separateAndSplit(
      allRounds,
      selectedBracket,
      matches,
      !hideFormatter &&
        orientation === "landscape" &&
        selectedBracket === "main"
    );
    let remainingBracketSize = { ...bracketSize };
    const matchWidth = remainingBracketSize.width / bracket.length;
    const heightOffset =
      20 +
      (selectedBracket === "main"
        ? (allRounds.secondFinal ? bracketSize.matchHeight * 1.6 : 0) +
          (allRounds.thirdFinal ? bracketSize.matchHeight * 1.6 : 0)
        : selectedBracket === "secondary"
        ? bracketSize.matchHeight * 1.6
        : 0);

    remainingBracketSize.height = remainingBracketSize.height - heightOffset;

    return (
      <div
        style={{
          width: bracketSize.width,
          height: bracketSize.height + 12,
          overflow: "auto",
          margin: "auto",
          ...containerStyle,
        }}
      >
        <svg
          height={bracketSize.height}
          width={bracketSize.width}
          className="svg-whole-bracket"
          id="svg-bracket"
        >
          <BracketFinals
            heightOffset={20}
            allRounds={allRounds}
            bracketSize={bracketSize}
            selectedBracket={selectedBracket}
            onSelectMatch={onSelectMatch}
            showFullTeamNames={showFullTeamNames}
            orientation={orientation}
            matchWidth={matchWidth}
          />
          {bracket.map((roundMatches, i) => {
            let X = (i * remainingBracketSize.width) / bracket.length;

            return (
              <g key={i}>
                {selectedBracket !== "secondary" && (
                  <text
                    x={X + matchWidth / 2}
                    y={20}
                    textAnchor="middle"
                    className="svg-text-header"
                  >
                    {getRoundName(
                      roundMatches.length,
                      orientation,
                      roundMatches[0].round === getRounds().final
                    )}
                  </text>
                )}
                {roundMatches.map((m, ii) => {
                  const blockStart =
                    (ii * remainingBracketSize.height) / roundMatches.length +
                    heightOffset;
                  const blockEnd =
                    ((ii + 1) * remainingBracketSize.height) /
                      roundMatches.length +
                    heightOffset;
                  let Y = {
                    start: blockStart,
                    end: blockEnd,
                  };

                  const bracketEnd = ii % 2 === 0 ? "top" : "bottom";
                  const isFinal = m.round === getRounds().final;
                  const isSemiFinal =
                    (m.round === getRounds().semi &&
                      orientation === "landscape") ||
                    selectedBracket === "secondary";
                  const textAnchor =
                    orientation === "portrait"
                      ? "start"
                      : isFinal
                      ? "middle"
                      : i < bracket.length / 2
                      ? "start"
                      : "end";
                  Y =
                    isFinal && orientation === "landscape"
                      ? {
                          start: remainingBracketSize.height / 2,
                          end: remainingBracketSize.height / 2 + 250,
                        }
                      : Y;
                  const yMatchStart =
                    (Y.end - Y.start) / 2 -
                    bracketSize.matchHeight / 2 +
                    Y.start;
                  return (
                    <g key={m._id || ii}>
                      <SingleMatch
                        match={m}
                        textAnchor={textAnchor}
                        width={matchWidth}
                        placement={{ X, Y: yMatchStart }}
                        bracketEnd={bracketEnd}
                        isSemiFinal={isSemiFinal}
                        isFinal={isFinal}
                        matchHeight={bracketSize.matchHeight}
                        spectate={spectate}
                        onSelectMatch={onSelectMatch}
                        showFullTeamNames={showFullTeamNames}
                      />
                      <MatchConnector
                        position={{
                          X,
                          Y: {
                            matchStart: yMatchStart,
                            matchEnd: yMatchStart + bracketSize.matchHeight,
                            blockStart,
                            blockEnd,
                          },
                        }}
                        width={matchWidth}
                        textAnchor={textAnchor}
                        isSemiFinal={isSemiFinal}
                        isFinal={isFinal}
                        orientation={orientation}
                        bracketEnd={bracketEnd}
                        isOnlyMatch={roundMatches.length === 1}
                        match={m}
                      />
                    </g>
                  );
                })}
              </g>
            );
          })}
        </svg>
      </div>
    );
  };

  return (
    <div className="text-center">
      <BracketButtons
        rounds={allRounds}
        selectedBracket={selectedBracket}
        setSelectedBracket={setSelectedBracket}
        orientation={orientation}
        setOrientation={setOrientation}
        bracketSize={bracketSize}
        setBracketSize={setBracketSize}
        showFullTeamNames={showFullTeamNames}
        setShowFullTeamNames={setShowFullTeamNames}
        downloadBracketImage={downloadBracketImage}
        hideFormatter={hideFormatter}
      />
      {renderBracket()}
    </div>
  );
};

export default PlayoffBracketCanvas;

import React from "react";

import BracketFinal from "./bracketFinal";
import { offsets } from "../../utils/bracketsUtil";

const BracketFinals = ({
  allRounds,
  bracketSize,
  selectedBracket,
  onSelectMatch,
  showFullTeamNames,
  orientation,
  matchWidth,
  heightOffset,
}) => {
  const mainBracketXPlacement = bracketSize.width / 4;
  const mainBracketMatchWidth = bracketSize.width / 2;

  return (
    <>
      {allRounds.secondFinal && selectedBracket === "main" && (
        <BracketFinal
          match={allRounds.secondFinal}
          bracketSize={bracketSize}
          placement={{
            X: mainBracketXPlacement,
            Y:
              heightOffset +
              (allRounds.thirdFinal ? bracketSize.matchHeight * 1.6 : 40),
          }}
          header="Final"
          width={mainBracketMatchWidth}
          onSelectMatch={onSelectMatch}
          showFullTeamNames={showFullTeamNames}
        />
      )}
      {allRounds.thirdFinal && selectedBracket === "main" && (
        <BracketFinal
          match={allRounds.thirdFinal}
          bracketSize={bracketSize}
          placement={{
            X: mainBracketXPlacement,
            Y: heightOffset + 30,
          }}
          header="Second Chance Final"
          width={mainBracketMatchWidth}
          onSelectMatch={onSelectMatch}
          showFullTeamNames={showFullTeamNames}
        />
      )}
      {allRounds.losersFinal && selectedBracket === "secondary" && (
        <BracketFinal
          match={allRounds.losersFinal}
          bracketSize={bracketSize}
          placement={{
            X: mainBracketXPlacement,
            Y: heightOffset + 30,
          }}
          width={mainBracketMatchWidth}
          header="Secondary Bracket Final"
          onSelectMatch={onSelectMatch}
          showFullTeamNames={showFullTeamNames}
        />
      )}
      {allRounds.thirdPlacePlayoff && (
        <BracketFinal
          match={allRounds.thirdPlacePlayoff}
          bracketSize={bracketSize}
          placement={{
            X:
              orientation === "portrait"
                ? bracketSize.width - matchWidth
                : bracketSize.width / 2 - matchWidth / 2,
            Y: bracketSize.height - bracketSize.matchHeight - offsets.lines,
          }}
          width={matchWidth}
          header="Third Place Playoff"
          onSelectMatch={onSelectMatch}
          showFullTeamNames={showFullTeamNames}
          minorMatch={true}
        />
      )}
    </>
  );
};

export default BracketFinals;

import React from "react";
import Joi from "joi-browser";
import toast from "../../utils/toast";

import Form from "../common/form/form";
import PasswordRequirements from "./passwordRequirements";
import MiniHeader from "../common/pageComponents/miniHeader";
import CustomPrompt from "../common/customs/customPrompt";
import {
  requestLoginCode,
  requestPasswordReset,
} from "../../services/userService";
import HeaderContext from "../../context/headerContext";
import WarningHeader from "../common/pageComponents/warningHeader";

class CombinedRegistrationForm extends Form {
  static contextType = HeaderContext;
  state = {
    data: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      shirtSize: "",
      genderIdentity: "",
      pronouns: "",
      teamName: "",
      divisionID: "",
      selectedTeam: false,
      teamID: "",
    },
    errors: {},
    loginOpen: false,
    registerOpen: false,
    resetOpen: false,
    codeOpen: false,
  };

  schema = {
    firstName: Joi.string()
      .optional()
      .allow("")
      .min(1)
      .max(50)
      .label("First Name"),
    lastName: Joi.string()
      .min(1)
      .max(50)
      .label("Last Name")
      .when("firstName", {
        is: Joi.string(),
        then: Joi.required(),
        otherwise: Joi.optional().allow(""),
      }),
    email: Joi.string().required().email().label("Email"),
    password: Joi.string().required().min(8).label("Password"),
    shirtSize: Joi.string().optional().allow(""),
    genderIdentity: Joi.string().optional().allow(""),
    pronouns: Joi.string().optional().allow("").max(99),
    teamName: Joi.string().optional().allow("").max(99),
    divisionID: Joi.string().optional().allow(""),
    selectedTeam: Joi.boolean().optional(),
    teamID: Joi.string().optional().allow(""),
  };

  componentDidUpdate(prevProps) {
    let update = false;
    if (this.props.data) {
      for (let prop in this.props.data) {
        if (this.props.data[prop] !== prevProps.data[prop]) update = true;
      }
    }
    if (update)
      this.setState({ data: { ...this.state.data, ...this.props.data } });
  }

  openForm = (form) => {
    this.setState({ loginOpen: false, registerOpen: false });
    this.setState({ [form]: true });
  };

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  handleAltLoginRequest = async (email, type) => {
    let trimmed;
    try {
      trimmed = String(email.trim().toLowerCase());
    } catch (error) {
      trimmed = "";
    }
    const ex = Joi.validate({ email: trimmed }, { email: this.schema.email });
    if (ex.error) return toast.error(ex.error.details[0].message);
    this.context.setLoading(true);
    this.context.setProgress([1]);
    if (type === "reset") {
      const response = await requestPasswordReset(trimmed, {
        callback: this.indicateProgress,
        bar: 0,
      });
      toast.info(response.data);
    } else if (type === "code") {
      const response = await requestLoginCode(trimmed, {
        callback: this.indicateProgress,
        bar: 0,
      });
      if (response.status === 200) {
        window.location = `/loginwithcode?email=${response.data}`;
      }
    }
    this.context.setLoading(false);
  };

  doSubmit = () => {
    const formType =
      this.props.only || (this.state.loginOpen ? "login" : "register");
    this.props.onSubmit(this.state.data, formType);
  };

  renderFormSection = (formType) => {
    let inputs = [
      this.renderInput(
        "email",
        "Email",
        "autofocus",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "username"
      ),
      this.renderInput(
        "password",
        "Password",
        "",
        "password",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "current-password"
      ),
    ];

    const info = this.props.org?.misc?.requiredPersonalInformation;
    if (!this.props.hidePersonalInfo && info) {
      let infoInputs = [];
      if (info.shirtSizeRequired)
        infoInputs.push(
          this.renderSelect(
            "shirtSize",
            "Shirt Size",
            ["XS", "S", "M", "L", "XL", "XXL"].map((size) => ({
              _id: size,
              name: size,
            }))
          )
        );
      if (info.genderIdentityRequired)
        infoInputs.push(
          this.renderSelect(
            "genderIdentity",
            "Gender Indentity",
            ["Male", "Female", "Non-Binary", "Prefer not to say"].map(
              (size) => ({
                _id: size,
                name: size,
              })
            )
          )
        );
      if (info.pronounsRequired)
        infoInputs.push(this.renderInput("pronouns", "Preferred Pronouns"));

      if (infoInputs.length) {
        if (info.informationRequestReason)
          infoInputs.unshift(
            <p className="text-center">{info.informationRequestReason}</p>
          );
        infoInputs.unshift(
          <div>
            <p className="text-center">
              Your league is requesting the following information. Providing it
              is completely optional. Any information collected here is only
              visible to league admins and your team captain.
            </p>
            <hr className="custom" />
          </div>
        );
        inputs.push(
          <div className="pop-box">
            {infoInputs.map((input, idx) => (
              <div key={idx}>{input}</div>
            ))}
          </div>
        );
      }
    }
    if (formType === "register")
      inputs.splice(
        1,
        0,
        this.renderInput("firstName", "First Name"),
        this.renderInput("lastName", "Last Name"),
        <PasswordRequirements />
      );
    inputs.push(
      this.renderValidatedButton(
        this.props.token
          ? "Change Password"
          : formType === "login"
          ? "Login"
          : "Register"
      )
    );
    return inputs.map((i, idx) => <div key={idx}>{i}</div>);
  };

  renderTeamSection = () => {
    if (this.props.getTeamInfo) {
      return this.props.divisions?.length ? (
        <div>
          <MiniHeader>Team Information</MiniHeader>
          <div className="form-divided-section">
            <form onSubmit={(e) => e.preventDefault()}>
              {this.renderInput("teamName", "Team Name")}
              {this.renderSelectByValueOption(
                "divisionID",
                "Division",
                this.props.divisions,
                "_id"
              )}
            </form>
          </div>
        </div>
      ) : (
        <WarningHeader>
          There are no available divisions to register into
        </WarningHeader>
      );
    } else if (this.props.selectTeam) {
      return (
        <div>
          <MiniHeader>Team</MiniHeader>
          <div className="form-divided-section">
            {this.renderCheckbox("selectedTeam", "I have a team to join")}
            {this.state.data.selectedTeam ? (
              this.props.teams.length ? (
                this.renderSelectByValueOption(
                  "teamID",
                  "Select Team",
                  this.props.teams.map((team) => ({
                    _id: team._id,
                    name:
                      team.name +
                      ": " +
                      team.teamCaptainName +
                      " - " +
                      team.divisionName,
                  })),
                  "_id"
                )
              ) : (
                <WarningHeader>There are no open teams</WarningHeader>
              )
            ) : null}
          </div>
        </div>
      );
    } else return null;
  };

  renderForgotPassword = () => {
    return !this.state.token ? (
      <div>
        <br />
        <div className="row">
          <div className="col">
            <button
              className="btn-href-link"
              onClick={() => this.setState({ resetOpen: true })}
            >
              Forgot Password
            </button>
          </div>
          {this.props.only === "login" ? (
            <div className="col">
              <button
                className="btn-href-link"
                onClick={() => this.setState({ codeOpen: true })}
              >
                Login with Code
              </button>
            </div>
          ) : null}
        </div>
        <CustomPrompt
          dialog="Enter your email to receive a password reset link"
          callback={(email) => this.handleAltLoginRequest(email, "reset")}
          isOpen={this.state.resetOpen}
          close={() => {
            this.setState({ resetOpen: false });
          }}
        />
        <CustomPrompt
          dialog="Enter your email to receive a login code"
          callback={(email) => this.handleAltLoginRequest(email, "code")}
          isOpen={this.state.codeOpen}
          close={() => {
            this.setState({ codeOpen: false });
          }}
        />
      </div>
    ) : null;
  };

  render() {
    const { loginOpen, registerOpen } = this.state;
    const { only } = this.props;

    return (
      <div>
        {only ? (
          <div>
            <form onSubmit={this.handleSubmit}>
              {this.renderFormSection(only)}
            </form>
            {this.renderForgotPassword()}
          </div>
        ) : (
          <React.Fragment>
            {this.renderTeamSection()}
            {!this.props.getTeamInfo || this.props.divisions.length ? (
              <div>
                <MiniHeader onClick={() => this.openForm("loginOpen")}>
                  I already have an Ultimate Scoreboard account
                </MiniHeader>
                {loginOpen && (
                  <div className="form-divided-section">
                    <form onSubmit={this.handleSubmit}>
                      {this.renderFormSection("login")}
                    </form>
                    {this.renderForgotPassword()}
                  </div>
                )}
                <br />
                <MiniHeader onClick={() => this.openForm("registerOpen")}>
                  I don't have an account
                </MiniHeader>
                {registerOpen && (
                  <div className="form-divided-section">
                    <form onSubmit={this.handleSubmit}>
                      {this.renderFormSection("register")}
                    </form>
                  </div>
                )}
                <br />
              </div>
            ) : null}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default CombinedRegistrationForm;

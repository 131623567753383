import React, { Component } from "react";
import toast from "../../utils/toast";
import orderBy from "lodash/orderBy";

import { getCompleteMatches } from "../../services/matchService";
import { getDivisions } from "../../services/divisionService";
import { getCurrentUser } from "../../services/userService";
import { splitQuery } from "../../utils/splitQuery";
import QuickLinks from "../common/pageComponents/quickLinks";
import HorizontalButtons from "../common/dataSorting/horizontalButtons";
import allowables from "../../utils/allowables";
import HeaderContext from "../../context/headerContext";
import NonFormSelect from "../common/form/nonFormSelect";
import MatchesTable from "../matches/matchesTable";
import MatchesCardView from "../matches/matchesCardView";
import { navigateTo } from "../common/customs/customLinks";
import TableCardSwitcher from "../common/cardView/tableCardSwitcher";
import { paginate } from "../../utils/paginate";

class MatchReview extends Component {
  static contextType = HeaderContext;
  state = {
    matches: [],
    divisions: [],
    selectedType: "review",
    matchTypes: [],
    selectedMatchType: { _id: "all", name: "All Match Types" },
    sortColumn: { path: "dateTime", order: "asc" },
    searchQuery: "",
    selectedDivision: { _id: "all", name: "All Divisions" },
    currentPage: 1,
    pageSize: 10,
  };

  refreshMatchesOnly = async () => {
    const res = await getCompleteMatches({
      callback: this.indicateProgress,
      bar: 0,
    });
    if (res.status === 200) {
      this.setState({ matches: res.data });
    } else toast.error(res.data);
  };

  async componentDidMount() {
    this.context.setLoading(true);
    this.context.setProgress([1]);
    await this.refreshMatchesOnly();
    const divisionsRes = await getDivisions({
      callback: this.indicateProgress,
      bar: 1,
    });
    if (divisionsRes.status === 200) {
      this.setState({
        divisions: [
          { _id: "all", name: "All Divisions" },
          ...divisionsRes.data,
        ],
        matchTypes: this.getMatchTypes(),
      });
      this.assignQuery(divisionsRes.data);
    } else toast.error(divisionsRes.data);
    this.context.setLoading(false);
  }

  getMatchTypes = () => {
    return [
      { _id: "all", name: "All Match Types" },
      ...allowables.matchTypes.map((t) => {
        return {
          _id: t.toLowerCase(),
          name: t,
        };
      }),
    ];
  };

  assignQuery = (divisions) => {
    const query = splitQuery(this.props.location.search);
    let { searchQuery, sortColumn, selectedType } = this.state;
    let selectedDivisionID = this.state.selectedDivision._id;
    let selectedMatchType = this.state.selectedMatchType._id;
    query.forEach((q) => {
      const thisQ = q.split("=");
      searchQuery = thisQ[0].includes("search") ? thisQ[1] : searchQuery;
      selectedType = thisQ[0].includes("type")
        ? thisQ[1].split("%20").join(" ")
        : selectedType;
      selectedMatchType = thisQ[0].includes("matchType")
        ? thisQ[1].split("%20").join(" ")
        : selectedMatchType;
      selectedDivisionID = thisQ[0].includes("division")
        ? thisQ[1]
        : selectedDivisionID;
      sortColumn.path = thisQ[0].includes("path") ? thisQ[1] : sortColumn.path;
      sortColumn.order = thisQ[0].includes("order")
        ? thisQ[1]
        : sortColumn.order;
    });
    const selectedDivision =
      divisions.find((d) => d._id === selectedDivisionID) ||
      this.state.selectedDivision;
    selectedMatchType = this.getMatchTypes().find(
      (t) => selectedMatchType === t._id
    );
    this.setState({
      searchQuery,
      sortColumn,
      selectedType,
      selectedMatchType,
      selectedDivision,
    });
  };

  indicateProgress = (progress, location) => {
    let { progress: currentProgress } = this.context;
    currentProgress[location.bar] =
      ((progress.loaded / progress.total) * 100) / currentProgress.length;
    this.context.setProgress(currentProgress);
  };

  downloadColumns = [
    "dateTime",
    "homeTeamName",
    "awayTeamName",
    "homeTeamAbbreviation",
    "awayTeamAbbreviation",
    "fieldName",
    "groupName",
    "type",
    "divisionName",
    "round",
    "matchNumber",
    "refereeName",
    "sport",
    "matchComplete",
    "matchAccepted",
    "homeTeamGoals",
    "awayTeamGoals",
    "homeTeamPKs",
    "awayTeamPKs",
    "refereeComments",
    "homeTeamProtest",
    "awayTeamProtest",
    "homeTeamProtestString",
    "awayTeamProtestString",
  ];

  pushHistoryString = (
    search,
    sortColumn,
    selectedType,
    selectedDivision,
    selectedMatchType
  ) => {
    const sort = sortColumn
      ? `path=${sortColumn.path}&order=${sortColumn.order}`
      : `path=${this.state.sortColumn.path}&order=${this.state.sortColumn.order}`;
    this.props.history.replace(
      `?search=${search || this.state.searchQuery}&${sort}&type=${
        selectedType || this.state.selectedType
      }&divisionID=${
        selectedDivision
          ? selectedDivision._id
          : this.state.selectedDivision._id
      }&matchType=${
        selectedMatchType
          ? selectedMatchType._id
          : this.state.selectedMatchType._id
      }`
    );
  };

  handlePageChange = (page) => {
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedDivision,
      page
    );
    this.setState({ currentPage: page });
  };

  handlePreviousPage = () => {
    const currentPage = this.state.currentPage;
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedDivision,
      currentPage - 1
    );
    this.setState({ currentPage: currentPage - 1 });
  };

  handleNextPage = () => {
    const currentPage = this.state.currentPage;
    this.pushHistoryString(
      this.state.searchQuery,
      this.state.sortColumn,
      this.state.selectedDivision,
      currentPage + 1
    );
    this.setState({ currentPage: currentPage + 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
    this.pushHistoryString(null, sortColumn);
  };

  handleSelectType = (selectedType) => {
    this.setState({ selectedType, currentPage: 1 });
    this.pushHistoryString(null, null, selectedType);
  };

  handleSelectMatchType = (event) => {
    const selectedMatchType = this.state.matchTypes.find(
      (t) => t._id === event.target.value
    );
    this.setState({ selectedMatchType });
    this.pushHistoryString(null, null, null, null, selectedMatchType);
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query });
    this.pushHistoryString(query);
  };

  handleSelectDivision = (event) => {
    const selectedDivision = this.state.divisions.find(
      (d) => d._id === event.target.value
    );

    this.setState({ selectedDivision });
    this.pushHistoryString(null, null, null, selectedDivision);
  };

  handleCardClick = (match) => {
    navigateTo(`/matchreview?q=${match._id}`, this.props.history, this.context);
  };

  filterMatches = () => {
    let {
      matches,
      selectedType,
      selectedMatchType,
      sortColumn,
      searchQuery,
      selectedDivision,
      divisions,
    } = this.state;
    const user = getCurrentUser();
    let filteredMatches = [];
    matches.forEach((m) => {
      let searchMatch,
        typeMatch,
        matchType,
        divisionMatch = false;
      if (
        (searchQuery &&
          (m.homeTeamName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            m.awayTeamName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()))) ||
        !searchQuery
      )
        searchMatch = true;
      if (
        (selectedType === "accepted" && m.matchAccepted === 1) ||
        (selectedType === "review" && m.matchAccepted === 0) ||
        (selectedType === "my review" &&
          m.matchAccepted === 0 &&
          (m.homeTeamCaptainID === user._id ||
            m.awayTeamCaptainID === user._id))
      )
        typeMatch = true;
      if (
        selectedMatchType._id === "all" ||
        m.type.toLowerCase().includes(selectedMatchType.name.toLowerCase())
      )
        matchType = true;
      if (
        selectedDivision._id === "all" ||
        selectedDivision._id === m.divisionID
      )
        divisionMatch = true;
      if (searchMatch && typeMatch && matchType && divisionMatch)
        filteredMatches.push(m);
    });

    const sortedMatches = orderBy(
      filteredMatches,
      [sortColumn.path],
      [sortColumn.order]
    );

    const matchesByPage = paginate(
      sortedMatches,
      this.state.currentPage,
      this.state.pageSize
    );

    let dbDivisions = orderBy(divisions, ["name"], ["asc"]);
    dbDivisions.forEach((d) => {
      d.numberOfMatches = matches.filter((m) => m.divisionID === d._id).length;
    });
    dbDivisions = [
      {
        _id: "all",
        name: "All Divisions",
        numberOfMatches: matches.length,
      },
      ...dbDivisions,
    ];

    return { matchesByPage, dbDivisions, filteredMatches };
  };

  render() {
    const {
      selectedType,
      sortColumn,
      searchQuery,
      selectedMatchType,
      selectedDivision,
      divisions,
      matchTypes,
      pageSize,
      currentPage,
    } = this.state;
    const { matchesByPage, filteredMatches } = this.filterMatches();
    const user = getCurrentUser();
    const options = user.role.includes("captain") ? ["my review"] : [];

    return (
      <React.Fragment>
        <HorizontalButtons
          buttons={[...options, "review", "accepted"]}
          onClick={this.handleSelectType}
          selectedType={selectedType}
        />
        <div className="row">
          <div className="col text-center">
            <NonFormSelect
              name="_id"
              label=""
              options={divisions}
              onChange={this.handleSelectDivision}
              value={selectedDivision._id}
              noDefaultOption={true}
            />
          </div>
          <div className="col text-center">
            <NonFormSelect
              name="_id"
              label=""
              options={matchTypes}
              onChange={this.handleSelectMatchType}
              value={selectedMatchType._id}
              noDefaultOption={true}
            />
          </div>
        </div>
        <TableCardSwitcher
          // overrideWidth={1000}
          search={{
            value: searchQuery,
            onChange: (q) => this.setState({ searchQuery: q }),
            placeholder: "Search by team name...",
          }}
          download={{
            name: "teams",
            data: matchesByPage,
            columns: this.downloadColumns,
          }}
          sort={{
            sortColumn,
            onSort: this.handleSort,
            label: "Sort Scorecards",
            options: [
              { _id: "dateTime", name: "Date" },
              { _id: "homeTeamName", name: "Home Team" },
              { _id: "awayTeamName", name: "Away Team" },
              { _id: "divisionName", name: "Division" },
              { _id: "seed", name: "Seed" },
              { _id: "groupName", name: "Group" },
            ],
          }}
          pagination={{
            count: filteredMatches.length,
            pageSize,
            currentPage,
            onPageChange: this.handlePageChange,
            onPreviousPage: this.handlePreviousPage,
            onNextPage: this.handleNextPage,
          }}
          TableComp={
            <MatchesTable
              org={this.props.org}
              matchesByPage={matchesByPage}
              onClick={this.handleCardClick}
              onDelete={this.setDeleteDialog}
              onFieldClick={this.handleFieldClick}
              onSort={this.handleSort}
              sortColumn={sortColumn}
              location={this.props.location}
              onAddVideoUrl={(match) => {
                this.setState({
                  selectedMatch: match,
                  videoInfo: {
                    url: match.videoUrl,
                  },
                });
                this.toggleModal("videoUrlOpen");
              }}
              onViewVideo={this.handleViewVideo}
              onRefresh={this.refreshMatchesOnly}
              onToggleStatLock={this.handleToggleStatLock}
              isReviewTable={true}
            />
          }
          CardComp={
            <MatchesCardView
              matches={matchesByPage}
              onClick={this.handleCardClick}
            />
          }
        />
        <QuickLinks
          fullBoard={true}
          org={this.props.org ? this.props.org : null}
        />
      </React.Fragment>
    );
  }
}

export default MatchReview;

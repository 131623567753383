import React, { Component } from "react";
import Table from "../common/table/table";
import { CLink } from "../common/customs/customLinks";
import { getCurrentUser } from "../../services/userService";
import { makeTableButton, combineButtons } from "../../utils/buttons";
import Tooltip from "../common/tooltip/tooltip";
import BoolVis from "../common/icons/booleanRep";

class DivisionsTable extends Component {
  columns = [
    {
      path: "name",
      label: "Division Name",
      content: (d) => (
        <CLink path={`/divisions?info&q=${d._id}`}>{d.name}</CLink>
      ),
    },
    {
      path: "season",
      label: "Current Season",
      content: (d) => (
        <CLink path={`/seasons?info&q=${d.seasonID?._id}`}>
          {d.seasonID?.name}
        </CLink>
      ),
    },
    {
      path: "maxPlayersPerTeam",
      label: "Max Players per Team",
      content: (d) => (d.maxPlayersPerTeam ? d.maxPlayersPerTeam : "No limit"),
    },
    {
      path: "maxTeams",
      label: "Max Registered Teams",
      content: (d) => (d.maxTeams ? d.maxTeams : "No limit"),
    },
    {
      path: "editingLocked",
      label: "Editing Locked",
      content: (d) => (
        <Tooltip
          content={
            <span>
              <b>{d.editingLocked ? "" : "Not "}Locked</b>
              <br />
              {d.editingLocked
                ? "Team captains can no longer edit their team information"
                : "Team captains can edit their team information, including name, abbreviation, colors and division"}
            </span>
          }
        >
          <BoolVis bool={d.editingLocked} />
        </Tooltip>
      ),
    },
    {
      path: "rostersLocked",
      label: "Rosters Locked",
      content: (d) => (
        <Tooltip
          content={
            <span>
              <b>{d.rostersLocked ? "" : "Not "}Locked</b>
              <br />
              {d.rostersLocked
                ? "Team captains can no longer add or edit players"
                : "Team captains can add, remove, or edit players on their team"}
            </span>
          }
        >
          <BoolVis bool={d.rostersLocked} />
        </Tooltip>
      ),
    },
  ];

  constructor(props) {
    super(props);
    const user = getCurrentUser();
    let buttons = [];
    if (user && user.role?.includes("admin")) {
      if (user.role.includes("super"))
        buttons.push(makeTableButton("purge", props.onPurge));
      if (props.onDelete)
        buttons.push(makeTableButton("delete", props.onDelete));
      buttons.push(makeTableButton("edit", "divisions"));
      this.columns.push(combineButtons(buttons));
    }
  }

  render() {
    const { divisionsByPage, onSort, sortColumn } = this.props;
    return (
      <Table
        columns={this.columns}
        data={divisionsByPage}
        sortColumn={sortColumn}
        onSort={onSort}
        keyProperty={"_id"}
        tableClass="table-custom"
        headerClass="thead-custom"
        bodyClass="tbody-custom"
        highlightOnHover={true}
      />
    );
  }
}

export default DivisionsTable;

import React from "react";
import range from "lodash/range";
import IconRender from "../icons/iconRender";

const Pagination = ({
  itemsCount,
  pageSize,
  onPageChange,
  currentPage,
  onPreviousPage,
  onNextPage,
}) => {
  const pageCount = itemsCount / pageSize;
  if (pageCount <= 1) return null;
  const pages = range(1, pageCount + 1);

  return (
    <nav aria-label="Page navigation" style={{ marginTop: 10 }}>
      <div className="custom-pagination">
        <div
          key="previous"
          className={
            currentPage === 1
              ? "custom-pagination-item disabled"
              : "custom-pagination-item clickable"
          }
          onClick={currentPage === 1 ? null : () => onPreviousPage()}
        >
          <IconRender name="chevronleft" />
        </div>
        {pages.map((page) => (
          <div
            key={page}
            className={
              "custom-pagination-item" +
              (page === currentPage ? " selected" : "")
            }
            style={{ cursor: "pointer" }}
            onClick={() => onPageChange(page)}
          >
            {page}
          </div>
        ))}
        <div
          key="next"
          className={
            currentPage >= Math.ceil(pageCount)
              ? "custom-pagination-item disabled"
              : "custom-pagination-item clickable"
          }
          onClick={
            currentPage >= Math.ceil(pageCount) ? null : () => onNextPage()
          }
        >
          <IconRender name="chevronright" />
        </div>
      </div>
    </nav>
  );
};

export default Pagination;

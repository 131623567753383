import useWindowDimensions from "../../../utils/useWindowDimensions";
import ModalPicker from "../dataSorting/modalPicker";
import SearchBox from "../dataSorting/searchBox";
import ExcelDownload from "../pageComponents/excelDownload";
import Pagination from "../dataSorting/pagination";
import { useEffect, useState } from "react";
import IconRender from "../icons/iconRender";
import TooltipButton from "../tooltip/tooltipButton";

const TableCardSwitcher = ({
  TableComp,
  CardComp,
  search,
  download,
  sort,
  pagination,
  overrideWidth,
}) => {
  const [orientation, setOrientation] = useState("");
  const { width } = useWindowDimensions();

  useEffect(() => {
    setOrientation(width > (overrideWidth || 700) ? "table" : "card");
  }, [width, overrideWidth]);

  const raiseSort = (path) => {
    const { sortColumn } = sort;
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = path?.toLowerCase().includes("name")
        ? "asc"
        : sort.initialSortOrder || "asc";
    }
    sort.onSort(sortColumn);
  };

  return (
    <div>
      <div className="row">
        {sort && (
          <div className="col-2">
            <ModalPicker
              buttonLabel="Sort"
              label={sort.label}
              options={sort.options || []}
              selectedValue={sort.sortColumn.path}
              onSelect={(path) => raiseSort(path._id)}
              minifyButton={true}
              buttonStyle={{ marginTop: 18 }}
            />
          </div>
        )}
        {search && (
          <div className="col">
            <SearchBox
              value={search.value}
              onChange={(q) => {
                if (pagination) {
                  pagination.onPageChange(1);
                }
                search.onChange(q);
              }}
              placeholder={search.placeholder || "Search by name..."}
            />
          </div>
        )}

        {download && (
          <div className="col-2">
            <ExcelDownload
              data={download.data}
              columns={download.columns}
              dataName={download.name}
              tooltipDirection="top-left"
            />
          </div>
        )}
      </div>
      {orientation === "table" ? TableComp : CardComp}
      {pagination && (
        <Pagination
          itemsCount={pagination.count}
          pageSize={pagination.pageSize}
          currentPage={pagination.currentPage}
          onPageChange={pagination.onPageChange}
          onPreviousPage={pagination.onPreviousPage}
          onNextPage={pagination.onNextPage}
        />
      )}
      <div style={{ float: "right", marginTop: 10 }}>
        <TooltipButton
          className="btn btn-sm btn-info m-1"
          onClick={() =>
            setOrientation(orientation === "table" ? "card" : "table")
          }
          tooltipText={`Switch to ${
            orientation === "table" ? "card" : "table"
          } view`}
          tooltipDirection="left"
        >
          <IconRender
            name={
              orientation
                ? orientation === "table"
                  ? "cards"
                  : "table"
                : "clear"
            }
          />
        </TooltipButton>
      </div>
    </div>
  );
};

export default TableCardSwitcher;

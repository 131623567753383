import React from "react";

const NewFeatureBadge = ({ inverse }) => {
  return (
    <span className={"badge badge-new" + (inverse ? " badge-inverse" : "")}>
      New
    </span>
  );
};

export default NewFeatureBadge;
